import * as React from 'react';
import * as R from 'ramda';
import { Text, Stack, PrimaryButton, Link } from 'office-ui-fabric-react/lib';
import { 
    JitArtifact,
    ServiceChange as ServiceChangeData, 
    IcM as IncidentData,
    DualCustodyFlowEnum,
} from '../../../store/artifact';
import { User } from '../../../store/app';
import { Input } from '../../../components';
import { ServiceChangeRequest } from './ServiceChangeRequest';
import { IcmIncidentRequest } from './IcmIncidentRequest';
import { TitleText } from '../../../shared';
import { 
    canUpdateServiceChangeByPhaseAndRole,
    canSaveServiceChange,
    displayUpdateServiceChangeTextField,
} from '../../../constants/actionLogic/serviceChange';
import {
    canUpdateIcMByPhaseAndRole,
    canSaveIcM,
    displayUpdateIcMTextField,
} from '../../../constants/actionLogic/icm';

export interface IServiceChangeProps {
    artifact: JitArtifact
    isLoading: boolean
    user: User
    dualCustodyFlow: DualCustodyFlowEnum
    validServiceChangeQueryUri: string
    style: string
    tryUpdateServiceChange: Function
    tryUpdateIcm: Function
    refreshHandler: () => void 
}

interface IServiceChangeState {
    serviceChangeInput: string
    icmInput: string
    errorMessage: string | JSX.Element 
    icmErrorMessage: string | JSX.Element 
}

export default class extends React.Component<IServiceChangeProps, IServiceChangeState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            serviceChangeInput: '',
            icmInput : '',
            errorMessage: '',
            icmErrorMessage: ''
        }

        this._onChangeServiceChange = this._onChangeServiceChange.bind(this);
        this._tryUpdateServiceChange = this._tryUpdateServiceChange.bind(this);
        this._onChangeIcM = this._onChangeIcM.bind(this);
        this._tryUpdateIcM = this._tryUpdateIcM.bind(this);
    }

    _onChangeServiceChange(serviceChangeInput: string): void {
        if (serviceChangeInput === ''){
            this.setState({ errorMessage: '' })
        }
        this.setState({ serviceChangeInput });
    }

    _tryUpdateServiceChange(): void {
        if (this.state.serviceChangeInput === ''){
            return;
        }

        this.props.tryUpdateServiceChange(this.state.serviceChangeInput).then((isValid: boolean) => {
            if (isValid) { 
                this.setState({ errorMessage: '' });
            } else {
                var errorMessage = (
                    <span>Service Change does not meet the criteria listed
                        <Link 
                            href={this.props.validServiceChangeQueryUri} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            > here</Link>
                    </span>
                );
                this.setState({ errorMessage });
            }
        })
    }

    _onChangeIcM(icmInput: string): void {
        if (icmInput === '') {
            this.setState({ icmErrorMessage: '' })
        }
        this.setState({ icmInput });
    }

    _tryUpdateIcM(): void {
        if (this.state.icmInput === '') {
            return;
        }

        this.props.tryUpdateIcm(this.state.icmInput).then((isValid: boolean) => {
            if (isValid) {
                this.setState({ icmErrorMessage: '' });
            } else {
                var icmErrorMessage = (
                    <span>ICM does not meet the criteria
                    </span>
                );
                this.setState({ icmErrorMessage });
            }
        })
    }

    render() {
        const UpdateServiceChange = (
            <Stack horizontal>
                <Stack.Item grow align="stretch">
                    <Input 
                        value={this.state.serviceChangeInput}
                        onChange={this._onChangeServiceChange}
                        label="Service Change Id"
                        required={this.props.artifact.serviceChange ? false : this.props.artifact.icM ? false : true}
                        disabled={!canUpdateServiceChangeByPhaseAndRole(this.props)}
                        errorMessage={this.state.errorMessage}
                    />
                </Stack.Item>
                <Stack.Item align="baseline">
                    <PrimaryButton 
                        styles={{root: {marginTop: 29}}}
                        text="Save"
                        disabled={!canSaveServiceChange(R.assoc('serviceChangeInput', this.state.serviceChangeInput, this.props))}
                        onClick={this._tryUpdateServiceChange}
                    />
                </Stack.Item>
            </Stack>
        );

        const ServiceChangeDataContainer = (serviceChange: ServiceChangeData) => (
            <div>
                <ServiceChangeRequest serviceChange={serviceChange} />
                <PrimaryButton 
                    title="Refresh"
                    styles={{ root: { marginTop: 10 } }}
                    onClick={() => this.props.refreshHandler()}
                    iconProps={{iconName: 'Refresh'}}
                    disabled={!canUpdateServiceChangeByPhaseAndRole(this.props)}
                />
            </div>
        );

        const UpdateIcM = (
            <Stack horizontal>
                <Stack.Item grow align="stretch">
                    <Input
                        value={this.state.icmInput}
                        onChange={this._onChangeIcM}
                        label="IcM Id"
                        required={this.props.artifact.icM ? false : this.props.artifact.serviceChange ? false: true}
                        disabled={!canUpdateIcMByPhaseAndRole(this.props)}
                        errorMessage={this.state.icmErrorMessage}
                    />
                </Stack.Item>
                <Stack.Item align="baseline">
                    <PrimaryButton
                        styles={{ root: { marginTop: 29 } }}
                        text="Save"
                        disabled={!canSaveIcM(R.assoc('icmInput', this.state.icmInput, this.props))}
                        onClick={this._tryUpdateIcM}
                    />
                </Stack.Item>
            </Stack>
        );

        const IncidentDataContainer = (incident: IncidentData) => (
            <div>
                <IcmIncidentRequest incident={incident} />
                <PrimaryButton
                    title="Refresh"
                    styles={{ root: { marginTop: 10 } }}
                    onClick={() => this.props.refreshHandler()}
                    iconProps={{ iconName: 'Refresh' }}                    
                />
            </div>
        );
        

        return (
            <div className={this.props.style}>
                <TitleText>Service Change/IcM</TitleText>                

                {/* UPDATE SERVICE CHANGE */}
                { displayUpdateServiceChangeTextField(this.props) ? UpdateServiceChange : null }

                {/* ICM */}
                {displayUpdateIcMTextField(this.props) ? UpdateIcM : null}

                {/* SERVICE CHANGE DATA */}
                {this.props.artifact.serviceChange ? ServiceChangeDataContainer(this.props.artifact.serviceChange) : this.props.artifact.icM ? IncidentDataContainer(this.props.artifact.icM) : <Text>No Service Change/Incident Data</Text>}

            </div>
        );
    }
}