import * as React from 'react';
import {
    JitArtifact, QuestionaireAnswer,
} from '../../store/artifact';
import { TitleText, classNames } from '../../shared';
import { QuestionNode } from '../../store/app'
import QuestionComponent, { IQuestionProps } from '../../components/QuestionComponent';
import { Label, Stack, Text } from 'office-ui-fabric-react/lib';
import {
    canUpdateServiceByPhaseAndRole
} from '../../constants/actionLogic/serviceTree';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

export interface IQuestionaireProps {
    artifact: JitArtifact
    root: QuestionNode
    style: string,
    onChange: Function
}

const convertToQuestionProp = (node: QuestionNode, style: string, index: number, anId:string, text:string): IQuestionProps => {
    return {
        answerId: anId, text: text, isDisabled: !canUpdateServiceByPhaseAndRole()
    , style: style, question: node, index: index, action: () => { } };
}



export default class extends React.Component<IQuestionaireProps> {

    answerArray: IQuestionProps[] = [];
    state = { answers: this.answerArray, isComplete: false, isDisabled: !canUpdateServiceByPhaseAndRole(this.props)  }

    constructor(props: IQuestionaireProps, context: any) {
        super(props, context);
    }

    calculateCompleted = (items: Array<IQuestionProps>): boolean => items.filter(item => item.text.trim() == "" && item.answerId == "").length == 0;

    handleTextChange = (value: string, x: IQuestionProps) => {
        var items = this.state.answers.filter(item => item.index < x.index);

        items.push(convertToQuestionProp(x.question, this.props.style, x.index, "", value));

        var otherAnswers = this.state.answers.filter(item => item.index > x.index);
        if (otherAnswers.length > 0) {
            items = items.concat(otherAnswers);
        }

        // continue on with questions.
        if (x.question.answers && x.question.answers.length > 0 && otherAnswers.length == 0) {
            items.push(convertToQuestionProp(x.question.answers[0], this.props.style, x.index + 1, "", ""));
        }

        var completed = this.calculateCompleted(items);
        this.setState(
            {
                isComplete: completed,
                answers: items
            });

        this.props.onChange(completed, items);
    }

    handleListSelect = (value: string, x: IQuestionProps) => {
        if (!value) {
            return
        }

        var q = x.question.answers.filter(item => item.answerId == value);
        var items = this.state.answers.filter(item => item.index < x.index);
        items.push(convertToQuestionProp(x.question, this.props.style, x.index, value, ""));

        if ((q[0].answers && q[0].answers.length > 0) || q[0].isText) {
            items.push(convertToQuestionProp(q[0], this.props.style, x.index + 1, "", ""));
        }

        var completed = this.calculateCompleted(items);

        this.setState(
            {
                isComplete: completed,
                answers: items
            });

        this.props.onChange(completed, items);
    }

    render() {
        if (this.props.root && this.state.answers.length == 0) {
            this.setState({ isComplete: false });
            this.setState(
                {
                    answers: [...this.state.answers, convertToQuestionProp(this.props.root, this.props.style, 0, "", "")]
                });

            this.props.onChange(false, []);
        }

        const helpRender = (helpLink: any) => {
            if (helpLink) {
                return (<Text
                    id="site_title"
                    aria-label="Privacy"
                    className={classNames.linkTitle}
                >For more information regarding JIT budgets, bucket/task definitions, or the JIT Reduction program, please see this <a href={helpLink} target="_blank">link</a></Text>);
            }

            return ""
        }

        const getQuestionTextTreeNode = (node: QuestionNode, qid: string) => {
            if (node.questionId == qid)
                return node.question;
            var children = node.answers;
            var output = "";
            children.forEach((child) => {
                var question = getQuestionTextTreeNode(child, qid);
                if (question != null && question.length > 0) {
                    output = question;
                }
            })
            return output;

        };

        
        const getAnswerTextTreeNode = (node: QuestionNode, aid: string) => {
            if (node.answerId == aid)
                return node.answer;
            var children = node.answers;
            var output = "";
            children.forEach((child) => {
                var answer = getAnswerTextTreeNode(child, aid);
                if (answer != null && answer.length > 0) {
                    output = answer;
                }
            })
            return output;

        };

        const displayQA = (qa: QuestionaireAnswer) => {
            var question = getQuestionTextTreeNode(this.props.root, qa.questionId);

            return (<div>
                <div style={{ backgroundColor: NeutralColors.gray20 }}><Label > {question}</Label></div>
                <div style={{ paddingLeft: '10px' }}><Label>{qa.answerId == "" ? qa.text : getAnswerTextTreeNode(this.props.root, qa.answerId)}</Label></div>
            </div>)
        }

        return (
            <div className={this.props.style}>
                <TitleText>C+E DevOps JIT Reduction Program: Questionnaire</TitleText>
                <Label>{this.props.root.sectionDescription}</Label>
                <div>{helpRender(this.props.root.helpLink)}</div>
                <Label></Label>
                {this.props.artifact.questionaireAnswers.length > 0 ? 

                    this.props.artifact.questionaireAnswers.map(node => {
                        return displayQA(node);
                    })
                 :                    
                        this.state.answers.map((x: any) => {
                            return (<QuestionComponent
                                question={x.question}
                                answerId={x.answerId}
                                text={x.text}
                                style={x.style}
                                index={x.index}
                                action={x.question.isText ? this.handleTextChange : this.handleListSelect}
                                isDisabled={this.state.isDisabled}
                            />)
                        })
                    
                }
            </div>
        );
    }
}