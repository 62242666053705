import * as R from 'ramda';
import { 
    ArtifactPhase, 
    AssignedArtifactRole, 
    DualCustodyFlowEnum,
    DualCustodyUserValidation,
} from '../../store/artifact';
import { AccessRole } from '../../store/app';
import { valueAtPathNotNullOrWhiteSpace } from '../helperFunctions';

// paths
export const code_contributors_path = ['artifact', 'codeContributors'];
export const mapped_services_path = ['artifact', 'mappedServices'];
export const serviceName_path = ['artifact', 'serviceName'];
export const service_change_path = ['artifact', 'serviceChange', 'id'];
export const service_change_input_path = 'serviceChangeInput';
export const icm_path = ['artifact', 'icM', 'id'];
export const icm_input_path = 'icmInput';
export const custodian_path = ['artifact', 'custodian'];
export const secondary_custodian_path = ['artifact', 'secondaryCustodians'];
export const custodian_input_path = ['custodianInput'];
export const role_path = ['artifact', 'role'];
export const phase_path = ['artifact', 'phase'];
export const dual_custody_flow_path = 'dualCustodyFlow'
export const user_role_path = ['user', 'role'];
export const user_alias_path = ['user', 'alias'];
export const is_loading_path = 'isLoading';
export const dual_custody_lite_validation_path = ['dualCustodyLiteValidation'];
export const fully_qualified_id_path = ['artifact', 'fullyQualifiedId'];
export const percent_complete_path = ['artifact', 'timeline', 'percentComplete'];
export const out_of_scope_time_path = ['artifact', 'timeline', 'outOfScopeTime'];

// roles 
export const hasNoRoleOnArtifact = R.pathEq(role_path, AssignedArtifactRole.None);
export const isAuthorRole = R.pathEq(role_path, AssignedArtifactRole.Author);
export const isCustodianRole = R.pathEq(role_path, AssignedArtifactRole.Custodian);
// Not required. Keeping this for future usage
export const isSecondaryCustodianRole = R.pathEq(role_path, AssignedArtifactRole.SecondaryCustodian);

// phases 
export const isOpenPhase = R.pathEq(phase_path, ArtifactPhase.Open);
export const isAuthorPhase = R.pathEq(phase_path, ArtifactPhase.Author);
export const isCustodianPhase = R.pathEq(phase_path, ArtifactPhase.Custodian);
//Phase Closed - Closed, ManualAdminClosed
export const isClosedPhase = R.either(R.pathEq(phase_path, ArtifactPhase.Closed), R.pathEq(phase_path, ArtifactPhase.ManualAdminClosed));

export const isReadOnly = R.anyPass([R.pathEq(phase_path, ArtifactPhase.ReadOnly), R.pathEq(phase_path, ArtifactPhase.AttestationNotRequired), R.pathEq(phase_path, ArtifactPhase.Staging)]);

// custodian
export const custodian = R.path(custodian_path);
export const custodianInput = R.path(custodian_input_path);
export const hasCustodian = valueAtPathNotNullOrWhiteSpace(custodian_path);

// service change
export const serviceChangeId = R.path(service_change_path);
export const hasServiceChange = valueAtPathNotNullOrWhiteSpace(service_change_path);
export const serviceChangeInput = R.prop(service_change_input_path);

// IcM
export const icmIncidentId = R.path(icm_path);
export const icmInput = R.prop(icm_input_path);
export const hasIcmIncident = valueAtPathNotNullOrWhiteSpace(icm_path);

// dual custody flow
export const dualCustodyFlow = R.prop(dual_custody_flow_path);
export const isDualCustodyFlowFull = (input: any) => R.equals(DualCustodyFlowEnum.Full, dualCustodyFlow(input));
export const isDualCustodyFlowLite = (input: any) => R.equals(DualCustodyFlowEnum.Lite, dualCustodyFlow(input));

//Mapped Services
export const mappedServices = R.path(mapped_services_path);
export const serviceNames = R.pipe(mappedServices, R.keys);

export const serviceName = R.path(serviceName_path);

// code contributors
export const isUnvalidatedCodeContributor = R.pathEq(dual_custody_lite_validation_path, DualCustodyUserValidation.NotValidated);
export const isValidatedNotCodeContributor = R.pathEq(dual_custody_lite_validation_path, DualCustodyUserValidation.ValidatedNotContributor);
export const isValidatedCodeContributor = R.pathEq(dual_custody_lite_validation_path, DualCustodyUserValidation.ValidatedContributor);
export const codeContributors = R.path(code_contributors_path);

// other
export const isAdmin = R.pathEq(user_role_path, AccessRole.Admin);
export const userAlias = R.path(user_alias_path);
export const isLoading = (input: any) => R.prop(is_loading_path, input);
export const fullyQualifiedId = R.path(fully_qualified_id_path);
export const tryGetPercentCompleteOrDefault = R.pathOr(0, percent_complete_path);
export const tryGetOutOfScopeTimeOrDefault = R.pathOr('', out_of_scope_time_path);