import * as React from 'react';
import * as R from 'ramda';
import { connect, Provider } from 'react-redux';
import { Store } from 'redux';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { ApplicationState } from './store';
import { AppState, getAssignedArtifacts, getUser, removeError, QuestionNode } from './store/app';
import { Layout } from './containers'
import Routes from './routes';
import { ErrorPopup } from './components';
import { isNotEmptyString } from './constants';

interface DispatchProps 
{
    getAssignedArtifacts: Function
    getUser: Function
    removeError: Function
}

interface MainProps {
    store: Store<ApplicationState>
    history: History
}

type AppProps = MainProps & AppState & DispatchProps;

class App extends React.PureComponent<AppProps>{
    public componentDidMount() {
        this.props.getUser();
        this.props.getAssignedArtifacts();
    }

    public render() {
        return (
            <Provider store={this.props.store}>
                <ConnectedRouter history={this.props.history}>
                    <Layout 
                        user={this.props.user}
                        sidebarItems={this.props.artifactIndexes} 
                        loading={this.props.loading}
                        loadingOperations={this.props.loadingOperations}
                    >
                        <Routes />
                    </Layout>
                    <ErrorPopup 
                        isOpen={isNotEmptyString(this.props.error)}
                        close={this.props.removeError}
                        error={this.props.error}
                    />

                </ConnectedRouter>
            </Provider>
        )
    }
}

const mapDispatchToProps = { getAssignedArtifacts, getUser, removeError }
export default connect((state: ApplicationState) => state.app, mapDispatchToProps)(App as any);