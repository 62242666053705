import * as React from 'react';
import { 
    DetailsList, 
    IGroup,
    IColumn,
    SelectionMode,
} from 'office-ui-fabric-react/lib';
import { 
    CodeContributorMap,
    GitContributorToRepos,
} from '../../../store/artifact';

interface IServiceNameContributorTableProps {
    gitContributors?: CodeContributorMap
}

interface IServiceNameContributorList {
    key: string
    repository: string
}

const getRepoNameFromRepoUrl = (repoUrl: string) => {
    let s = repoUrl.lastIndexOf('_git/');
    let sub = repoUrl.substring(s+5);

    return sub;
}

const getGroup = (groupName: string, startIndex: number, count: number): IGroup => {
    return { key: groupName, name: groupName.toLowerCase(), startIndex: startIndex, count: count, isCollapsed: true }
}

export default class ServiceNameContributorTable extends React.Component<IServiceNameContributorTableProps> {
    public render() {
        let items: IServiceNameContributorList[] = [];
        let groups: IGroup[] = []
        let columns: IColumn[] = [
            { key: 'repository', name: 'Code Contributors', fieldName: 'repository', minWidth: 100, maxWidth: 200 },
        ];

        if (this.props.gitContributors){
            let repoCount = 0;
            for (var alias in this.props.gitContributors) {        
                let contributorToRepo: GitContributorToRepos = this.props.gitContributors[alias];

                // add group (alias)
                let group: IGroup = getGroup(
                    `${alias} (${contributorToRepo.contributor.displayName})`, 
                    repoCount, 
                    contributorToRepo.repositories.length);
                repoCount += contributorToRepo.repositories.length;
                groups.push(group);

                // add items (repositories)
                for (let i = 0; i < contributorToRepo.repositories.length; i++){
                    let repository = getRepoNameFromRepoUrl(contributorToRepo.repositories[i]);
                    items.push({
                        key: `${alias}_${repository}`,
                        repository: repository
                    });
                }
            }
        }

        return (
            <div>
                <DetailsList 
                    items={items}
                    groups={groups}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    disableSelectionZone={true}
                    groupProps={{ showEmptyGroups: true}}
                    compact={true}
                />
            </div>
        );
    }
}