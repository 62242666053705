import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { TitleText } from '../../shared';
import { JitArtifact, ContentTimestampTuple } from '../../store/artifact';
import { ActivityItem, mergeStyleSets, Icon, Link } from 'office-ui-fabric-react';
import { mapIndexed } from '../../constants';
import { getEvents } from '../../store/artifact';
import ReactJson from 'react-json-view'

interface IJitEventsProps {
    artifact: JitArtifact
    isLoading: boolean
    style: string
    artifactEvents: any
}

interface PropsFromDispatch {
    getEvents: Function
}

type JitEventProps = IJitEventsProps & PropsFromDispatch;


const JitEvents: React.FC<JitEventProps> = (props: JitEventProps) => {
    const timeline_event_path = ['artifact', 'timeline', 'timelineEvents'];
    const getTimelineEvents = R.pathOr([], timeline_event_path);
    const getTimelineEventCount = R.pipe(getTimelineEvents, R.length);
    const rawEvents = getTimelineEvents(props);
    const eventCount = getTimelineEventCount(props);    
    const events = mapIndexed((val, idx) => {
        const isStartOrEndEvent = R.or(R.equals(0, idx), R.equals(eventCount - 1, idx));
        return getActivityItem(idx, isStartOrEndEvent, val as ContentTimestampTuple);
    }, rawEvents);
    
    const LinkToEvents = (<Link onClick={() => props.getEvents(props.artifact.fullyQualifiedId)}>{props.artifactEvents ? "Hide json": "Show all jit events json"}</Link>);
    const JsonView = (<div><ReactJson src={props.artifactEvents} theme={{
        base00: "white",
        base01: "#ddd",
        base02: "#ddd",
        base03: "#444",
        base04: "purple",
        base05: "#444",
        base06: "#444",
        base07: "#444",
        base08: "#444",
        base09: "rgba(70, 70, 230, 1)",
        base0A: "rgba(70, 70, 230, 1)",
        base0B: "rgba(70, 70, 230, 1)",
        base0C: "rgba(70, 70, 230, 1)",
        base0D: "rgba(70, 70, 230, 1)",
        base0E: "rgba(70, 70, 230, 1)",
        base0F: "rgba(70, 70, 230, 1)"
    }} iconStyle="square" name="Artifact" displayDataTypes={false} collapsed={2} /></div>);
    
        return (
        <div className={props.style}>
            <TitleText>Jit Events</TitleText>
                {events}
                <div className={classNames.linkText}>{LinkToEvents}</div>
            {props.artifactEvents ? JsonView : ""}
        </div>
    );
}

const getActivityItem = (index: number, isStartOrEnd: boolean,  rawEvent: ContentTimestampTuple) => { 
    return ( 
        <ActivityItem 
            key={index}
            activityDescription={
                [<span key={1} className={classNames.nameText}>{rawEvent.content}</span>,
                <span key={2}> {rawEvent.timestamp}</span>]
            }
            activityIcon={<Icon className={classNames.blue} iconName={ isStartOrEnd ? 'LocationFill' : 'Separator'} />}
            isCompact={false}
            className={classNames.exampleRoot}
        />
    );
}

const classNames = mergeStyleSets({
    exampleRoot: {
      marginTop: '20px'
    },
    nameText: {
      fontWeight: 'bold'
    },
    linkText: {
        marginTop: '10px',
        fontSize: 12
    },
    blue: [{ color: SharedColors.cyanBlue10 }],
});

const mapDispatchToProps: PropsFromDispatch = {
    getEvents: getEvents
}

export default connect(null, mapDispatchToProps)(JitEvents);

