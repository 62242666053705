import * as React from 'react';
import * as R from 'ramda';
import { Stack, Text, IconButton, IStackTokens, ITextStyles } from 'office-ui-fabric-react/lib';
import { Card, ICardTokens } from '@uifabric/react-cards';
import { FontWeights } from '@uifabric/styling';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { history } from '../utils';
import { classNames } from '../shared';
import { ArtifactMetaData, User } from '../store/app';
import { GetStatusFromPhase, mapIndexed } from '../constants';
import { AdminDiagnosticActions } from '../containers';

interface ISidebarProps
{
    user: User
    sidebarItems: ArtifactMetaData[]
    isSidebarLoading: boolean
}

interface SidebarState 
{
    selectedKey: string
}

const descriptionTextStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.regular
    }
};

export default class extends React.Component<ISidebarProps, SidebarState> {
    constructor(props: any, context: any){
        super(props, context);
        this.state = {
            selectedKey: history.location.hash,
        }

        this._navigateToArtifact = this._navigateToArtifact.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedKey: history.location.hash })
    }

    componentDidUpdate() {
        if (this.state.selectedKey !== history.location.hash) {
            this.setState({ selectedKey: history.location.hash })
        }
    }

    _navigateToArtifact = (url: string): void => {
        this.setState({ selectedKey: `#/${url}`});
        history.push(`/#/${url}`);
    }

    render() {
        const getSidebarItem = (item: ArtifactMetaData, index: number) =>
            <div style={{ padding: '8px 10px 2px 10px', marginTop: 0 }} key={index} >
                <Card onClick={() => this._navigateToArtifact(item.fullyQualifiedId)} compact tokens={cardTokens} styles={{ root: { backgroundColor: this.state.selectedKey === `#/${item.fullyQualifiedId}` ? NeutralColors.gray30 : NeutralColors.white, marginLeft: 6, marginRight: 6, minWidth: 1, marginTop: '2px !important', marginBottom: '2px !important' } }}>
                    <Card.Section styles={{ root: { padding: 2 } }}>
                        <Text variant="small" styles={siteTextStyles}>
                            Status: {GetStatusFromPhase(item.phase)}
                        </Text>  
                        {item.author == null ?
                            <Text styles={descriptionTextStyles}>
                            </Text> :
                        item.author.length == 0 ?
                            <Text styles={descriptionTextStyles}>
                            </Text> :
                            <Text styles={descriptionTextStyles}>
                                Author: {item.author}
                            </Text>
                        }
                        <Text styles={descriptionTextStyles}>
                            Role: {item.role.toString()}
                        </Text>
                        <Text variant="xSmall" styles={helpfulTextStyles}>
                            Id: {item.source}/{item.id}
                        </Text>
                        <Text variant="xSmall" styles={helpfulTextStyles}>
                            Start: {item.jitStartTime}
                        </Text>
                    </Card.Section>
                </Card>
            </div>
        const ArtifactList: React.FC<{ items: ArtifactMetaData[] }> = ({ items }) => {
            const div = (children: any, key: any) => <div key={key}>{children}</div>;
            const sidebarItems = mapIndexed((val, idx: number) => getSidebarItem(val as ArtifactMetaData, idx), items);
            return div(sidebarItems, 'artifactList');
        };

        let sidebarCount = getSidebarCount(this.props);
        return (
            <Stack tokens={stackTokens20} grow={1} className={classNames.sidebar}> 
                <AdminDiagnosticActions
                    user={this.props.user}
                    isLoading={this.props.isSidebarLoading}
                    style={classNames.attestationClosed}
                /> 
                <ArtifactHeader sidebarCount={sidebarCount} />
                <ArtifactList items={this.props.sidebarItems} />
            </Stack>
        );
    }
}

const getSidebarCount = R.cond([
    [R.pathOr(R.T, ['isSidebarLoading']), R.always(null)],
    [R.T, R.pathOr(0, ['sidebarItems', 'length'])]
]);

const stackTokens20: IStackTokens = { childrenGap: 20 };

const cardTokens: ICardTokens = { childrenMargin: 5 };

const siteTextStyles: ITextStyles = {
    root: {
        color: '#025F52',
        fontWeight: FontWeights.semibold
    }
};


const helpfulTextStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.regular
    }
};

const ArtifactHeader: React.FC<{sidebarCount: any}> = ({ sidebarCount }) => 
    <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 30 }}>
            <Text variant={'mediumPlus'} styles={{ root: { fontWeight: 600 } }}>
                <IconButton
                disabled={false}
                checked={false}
                iconProps={{ iconName: 'WorkItem', styles: { root: { fontSize: '20px', marginRight: 12, marginLeft: -12 } } }}
                title="Attestation"
                ariaLabel="Attestation"/>
                Artifacts {sidebarCount ? `(${sidebarCount})` : ""}
            </Text>
    </div>
