import { map, call } from 'ramda';
import { AppActionTypes, Action } from '../store/app';
import { disableLoading } from './';

const getAction = (type: string, payload: any): Action => ({ type, payload })

export const removeLoadingAction = (actionType: string): Action => 
    getAction(AppActionTypes.REMOVE_LOADING_OPERATION, actionType);

export const dispatchActions = (dispatch: any, actions: any) => 
    map((action) => call(dispatch, action), actions);

export const dispatchSuccess = (dispatch: any, action: any, payload: any) => 
    dispatchActions(dispatch, [getAction(action, payload), removeLoadingAction(action)]);

export const dispatchFail = async (dispatch: any, action: any, error: any) => {
    try {
        dispatch({ type: AppActionTypes.ADD_ERROR, payload: await error.text() })
        disableLoading(dispatch, action);
    } catch(error){
        disableLoading(dispatch, action);
    }
}