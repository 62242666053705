import { 
    ArtifactActionTypes, 
    DualCustodyFlowEnum,
    DualCustodyUserValidation,
} from './types';
import { getAssignedArtifacts } from '../app';
import { 
    toJson,
    enableLoading,
    dispatchFail,
    rejectOnFailResponse,
    dispatchActions,
    removeLoadingAction,
} from '../../utils';
import * as Api from '../../utils/api';
import { 
    getArtifactUri, 
    attestUri, 
    tryUpdateServiceChangeUri,
    tryUpdateIcmUri,
    updateServiceUri,
    updateCustodianUri,
    recallArtifactUri,
    loadCodeContributorsIntoArtifactUri,
    sendArtifactForReviewUri,
    adminChangePhaseToAuthorUri,
    adminCreateTestArtifactUri,
    getEventsUri,
    adminCloseArtifactUriWithBody
} from './endpoints';
import {cond, T, F} from 'ramda';

export const getArtifact = (fullyQualifiedId: string): (d: any) => Promise<any> =>
    (dispatch: any) =>
        enableLoading(dispatch, ArtifactActionTypes.GET_ARTIFACT)
            .chain(getArtifactUri(fullyQualifiedId))
            .chain(Api.Get)
            .chain(rejectOnFailResponse)
            .chain(toJson)
            .promise()
            .then((payload: any) => dispatchActions(dispatch, [
                ({ type: ArtifactActionTypes.GET_ARTIFACT, payload }),
                removeLoadingAction(ArtifactActionTypes.GET_ARTIFACT)]))
            .catch((error: any) => dispatchFail(dispatch, ArtifactActionTypes.GET_ARTIFACT, error));

export const getEvents = (fullyQualifiedId: string): (d: any) => Promise<any> =>
    (dispatch: any) =>
        enableLoading(dispatch, ArtifactActionTypes.GET_ARTIFACT_EVENT)
            .chain(getEventsUri(fullyQualifiedId))
            .chain(Api.Get)
            .chain(rejectOnFailResponse)
            .chain(toJson)
            .promise()
            .then((payload: any) => dispatchActions(dispatch, [
                ({ type: ArtifactActionTypes.GET_ARTIFACT_EVENT, payload }),
                removeLoadingAction(ArtifactActionTypes.GET_ARTIFACT_EVENT)]))
            .catch((error: any) => dispatchFail(dispatch, ArtifactActionTypes.GET_ARTIFACT_EVENT, error));

export const attest = (fullyQualifiedId: string, dualCustodyFlow: DualCustodyFlowEnum): (d: any) => void =>
    (dispatch: any) => 
    enableLoading(dispatch, ArtifactActionTypes.ATTEST)
    .chain(Api.PostWithBody(attestUri, JSON.stringify({fullyQualifiedId, dualCustodyFlow})))
    .chain(rejectOnFailResponse)
    .fork(
        (error: any) => dispatchFail(dispatch, ArtifactActionTypes.ATTEST, error),
        () => dispatchActions(dispatch, [
                getArtifact(fullyQualifiedId),
                getAssignedArtifacts(),
                removeLoadingAction(ArtifactActionTypes.ATTEST)]));

export const tryUpdateServiceChange = (fullyQualifiedId: string, serviceChangeNumber: string, dualCustodyFlow: DualCustodyFlowEnum): (d: any) => Promise<boolean> =>
    (dispatch) =>
    enableLoading(dispatch, ArtifactActionTypes.TRY_UPDATE_SERVICE_CHANGE)
    .chain(Api.PostWithBody(tryUpdateServiceChangeUri, JSON.stringify({fullyQualifiedId, serviceChangeNumber, dualCustodyFlow})))
    .chain(rejectOnFailResponse)
    .promise()
    .then((payload) => {
        dispatchActions(dispatch, [ 
            getArtifact(fullyQualifiedId),
            removeLoadingAction(ArtifactActionTypes.TRY_UPDATE_SERVICE_CHANGE)]);
        return cond([
            [Api.is200Response, T],
            [Api.is404Response, F],
            [T, F]])(payload);
        })
    .catch((error) => dispatchFail(dispatch, ArtifactActionTypes.TRY_UPDATE_SERVICE_CHANGE, error));

export const tryUpdateIcm = (fullyQualifiedId: string, incidentId: string, dualCustodyFlow: DualCustodyFlowEnum): (d: any) => Promise<boolean> =>
    (dispatch) =>
        enableLoading(dispatch, ArtifactActionTypes.TRY_UPDATE_ICM)
            .chain(Api.PostWithBody(tryUpdateIcmUri, JSON.stringify({ fullyQualifiedId, incidentId, dualCustodyFlow })))
            .chain(rejectOnFailResponse)
            .promise()
            .then((payload) => {
                dispatchActions(dispatch, [
                    getArtifact(fullyQualifiedId),
                    removeLoadingAction(ArtifactActionTypes.TRY_UPDATE_ICM)]);
                return cond([
                    [Api.is200Response, T],
                    [Api.is404Response, F],
                    [T, F]])(payload);
            })
            .catch((error) => dispatchFail(dispatch, ArtifactActionTypes.TRY_UPDATE_ICM, error));

export const updateServiceAction = (fullyQualifiedId: string, serviceId: string): (d: any) => void =>
    (dispatch: any) =>
        enableLoading(dispatch, ArtifactActionTypes.UPDATE_SERVICE)
            .chain(Api.PostWithBody(updateServiceUri, JSON.stringify({ fullyQualifiedId, serviceId })))
            .chain(rejectOnFailResponse)
            .fork(
                (error: any) => dispatchFail(dispatch, ArtifactActionTypes.UPDATE_SERVICE, error),
                () => dispatchActions(dispatch, [
                    getArtifact(fullyQualifiedId),
                    removeLoadingAction(ArtifactActionTypes.UPDATE_SERVICE)]));

export const updateCustodian = (fullyQualifiedId: string, custodian: string): (d: any) => void =>
    (dispatch: any) => 
    enableLoading(dispatch, ArtifactActionTypes.UPDATE_CUSTODIAN)
    .chain(Api.PostWithBody(updateCustodianUri, JSON.stringify({fullyQualifiedId, custodian})))
    .chain(rejectOnFailResponse)
    .fork(
        (error: any) => dispatchFail(dispatch, ArtifactActionTypes.UPDATE_CUSTODIAN, error),
        () => dispatchActions(dispatch, [
                getArtifact(fullyQualifiedId),
                removeLoadingAction(ArtifactActionTypes.UPDATE_CUSTODIAN)]));

export const sendArtifactForReview = (fullyQualifiedId: string, answers: any): (d: any) => void =>
    (dispatch: any) =>
    enableLoading(dispatch, ArtifactActionTypes.SEND_ARTIFACT_FOR_REVIEW)
    .chain(Api.PostWithBody(sendArtifactForReviewUri(fullyQualifiedId), JSON.stringify(answers)))
    .chain(rejectOnFailResponse)
    .fork(
        (error: any) => dispatchFail(dispatch, ArtifactActionTypes.SEND_ARTIFACT_FOR_REVIEW, error),
        () => dispatchActions(dispatch, [
                getArtifact(fullyQualifiedId),
                getAssignedArtifacts(),
                removeLoadingAction(ArtifactActionTypes.SEND_ARTIFACT_FOR_REVIEW)]));

export const recallArtifact = (fullyQualifiedId: string): (d: any) => void =>
    (dispatch: any) =>
    enableLoading(dispatch, ArtifactActionTypes.RECALL_ARTIFACT)
    .chain(recallArtifactUri(fullyQualifiedId))
    .chain(Api.Post)
    .chain(rejectOnFailResponse)
    .fork(
        (error: any) => dispatchFail(dispatch, ArtifactActionTypes.RECALL_ARTIFACT, error),
        () => dispatchActions(dispatch, [
                getArtifact(fullyQualifiedId),
                getAssignedArtifacts(),
                removeLoadingAction(ArtifactActionTypes.RECALL_ARTIFACT)]));

export const adminCloseArtifact = (fullyQualifiedId: string, answers: any): (d: any) => void =>
    (dispatch: any) =>
    enableLoading(dispatch, ArtifactActionTypes.ADMIN_CLOSE_ARTIFACT)
    .chain(Api.PostWithBody(adminCloseArtifactUriWithBody(fullyQualifiedId), JSON.stringify(answers)))
    .chain(rejectOnFailResponse)
    .fork(
        (error: any) => dispatchFail(dispatch, ArtifactActionTypes.ADMIN_CLOSE_ARTIFACT, error),
        () => dispatchActions(dispatch, [
                clearArtifact(),
                removeLoadingAction(ArtifactActionTypes.ADMIN_CLOSE_ARTIFACT)]));

export const adminChangePhaseToAuthor = (fullyQualifiedId: string): (d: any) => void =>
    (dispatch: any) =>
        enableLoading(dispatch, ArtifactActionTypes.ADMIN_PHASE_TO_AUTHOR)
            .chain(adminChangePhaseToAuthorUri(fullyQualifiedId))
            .chain(Api.Post)
            .chain(rejectOnFailResponse)
            .fork(
                (error: any) => dispatchFail(dispatch, ArtifactActionTypes.ADMIN_PHASE_TO_AUTHOR, error),
                () => dispatchActions(dispatch, [
                    getArtifact(fullyQualifiedId),
                    getAssignedArtifacts(),
                    removeLoadingAction(ArtifactActionTypes.ADMIN_PHASE_TO_AUTHOR)]));

export const adminCreateTestArtifact = (): (d: any) => void =>
    (dispatch: any) =>
        enableLoading(dispatch, ArtifactActionTypes.ADMIN_CREATE_TEST_ARTIFACT)
            .chain(adminCreateTestArtifactUri("Author"))
            .chain(Api.Post)
            .chain(rejectOnFailResponse)
            .fork(
                (error: any)     => dispatchFail(dispatch, ArtifactActionTypes.ADMIN_CREATE_TEST_ARTIFACT, error),
                () => dispatchActions(dispatch, [
                    clearArtifact(),
                    getAssignedArtifacts(),
                    removeLoadingAction(ArtifactActionTypes.ADMIN_CREATE_TEST_ARTIFACT)]));


export const loadCodeContributorsIntoArtifact = (fullyQualifiedId: string): (d: any) => Promise<any> =>
    (dispatch:any) =>
    enableLoading(dispatch, ArtifactActionTypes.LOAD_CODE_CONTRIBUTORS_INTO_ARTIFACT)
    .chain(loadCodeContributorsIntoArtifactUri(fullyQualifiedId))
    .chain(Api.Post)
    .chain(rejectOnFailResponse)
    .promise()
    .catch((error: any) => { dispatchFail(dispatch, ArtifactActionTypes.LOAD_CODE_CONTRIBUTORS_INTO_ARTIFACT, error); throw error })
    .then(() => dispatch(getArtifact(fullyQualifiedId)))
    .then(() => dispatch(removeLoadingAction(ArtifactActionTypes.LOAD_CODE_CONTRIBUTORS_INTO_ARTIFACT)))
    ;


export const clearArtifact = (): (d: any) => void =>
    (dispatch: any) => 
    dispatch({ type: ArtifactActionTypes.CLEAR_ARTIFACT });

export const changeDualCustodyFlow = (dualCustodyFlow: DualCustodyFlowEnum): (d: any) => void => 
    (dispatch: any) => 
    dispatch({type: ArtifactActionTypes.SET_DUAL_CUSTODY_FLOW, payload: dualCustodyFlow});

export const updateDualcustodyLiteValidation = (dualCustodyLiteValidation: DualCustodyUserValidation): (d: any) => void => 
    (dispatch: any) => 
    dispatch({type: ArtifactActionTypes.UPDATE_DUAL_CUSTODY_LITE_VALIDATION, payload: dualCustodyLiteValidation});