import { Reducer } from 'redux';
import { ArtifactState, ArtifactActionTypes, DualCustodyFlowEnum, DualCustodyUserValidation } from './types';

const initialState: ArtifactState = {
    artifact: undefined,
    artifactEvents: undefined,
    dualCustodyFlow: DualCustodyFlowEnum.Full,
    dualCustodyLiteValidation: DualCustodyUserValidation.NotValidated
}

const reducer: Reducer<ArtifactState> = (state = initialState, action) => {
    switch (action.type) {
        case ArtifactActionTypes.GET_ARTIFACT:
            return { ...state, artifact: action.payload, artifactEvents: undefined };
        case ArtifactActionTypes.GET_ARTIFACT_EVENT:
            if (state.artifactEvents) {
                return { ...state, artifactEvents: undefined };
            }
            else {
                return { ...state, artifactEvents: action.payload };
            }
        case ArtifactActionTypes.CLEAR_ARTIFACT:
            return { ...initialState };
        case ArtifactActionTypes.SET_DUAL_CUSTODY_FLOW:
            return {
                ...state,
                dualCustodyLiteValidation: DualCustodyUserValidation.NotValidated,
                dualCustodyFlow: action.payload
            };
        case ArtifactActionTypes.UPDATE_DUAL_CUSTODY_LITE_VALIDATION:
            return { ...state, dualCustodyLiteValidation: action.payload };
        default:
            return state;
    }
};

export { reducer as artifactReducer }