import * as React from 'react';
import { Label } from 'office-ui-fabric-react/lib/';
import { TitleText } from '../../shared';
import { ArtifactPhase, JitArtifact } from '../../store/artifact';

interface IPhaseProps {
    artifact: JitArtifact
    style: string
}

export const NoAction: React.FC<IPhaseProps> = (props) => {
    return (
        <div className={props.style}>
            <TitleText>No Action Required</TitleText>
            <div>                
                {props.artifact.phase === ArtifactPhase.ReadOnly ? <Label>No action required on this artifact as this is ReadOnly Jit Session. </Label> :
                    props.artifact.phase === ArtifactPhase.Staging ? <Label>No action required on this artifact as this is Compliant Geneva Action. </Label> :
                        <Label>No action required on this artifact. </Label>
                  }            
            </div>
        </div>
    );
}