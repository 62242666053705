import * as R from 'ramda';
import {
    isLoading,
    isClosedPhase,
    isAuthorRole,
    isCustodianRole,
    isOpenPhase,
    isAuthorPhase,
    isAdmin,
    isReadOnly,
    hasNoRoleOnArtifact,
} from '.';

export const canUpdateServiceByPhaseAndRole = R.allPass([
    R.complement(isLoading),
    R.complement(isClosedPhase),
    R.complement(isReadOnly),
    R.complement(isCustodianRole),
    R.anyPass([isOpenPhase, isAuthorPhase]),
    R.anyPass([isAuthorRole, hasNoRoleOnArtifact])
]);

