import * as R from 'ramda';
import * as F from 'fluture'
import { is200Response } from './';

export const toJson = F.encaseP((req: any) => req.json());

export const rejectOnFailResponse = (res: any) => 
    R.ifElse(
        is200Response,
        R.always(F.resolve(res)), 
        R.always(F.reject(res))
    )(res);

