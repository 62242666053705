import * as R from 'ramda';
import { 
    isLoading, 
    custodian,
    custodianInput,
    isDualCustodyFlowFull,
    isClosedPhase,
    isAuthorRole,
    isOpenPhase,
    isAuthorPhase,
    isReadOnly,
    isAdmin,
    hasNoRoleOnArtifact,
    hasCustodian,
} from '.';
import {
    isValidEmail,
    isMicrosoftEmail,
} from '../regex';

const toLower = (s: any) => R.toLower(s);
const isCustodianEqualToInput = (input: any) => R.equals(custodian(input), custodianInput(input));
const isCustodianInputEmpty = (input: any) => R.equals('', custodianInput(input));
const isCustodianInputEmailValid = R.pipe(custodianInput, toLower, R.both(isValidEmail, isMicrosoftEmail));

export const isCustodianRequired = R.allPass([
    R.complement(isClosedPhase),
    R.complement(hasCustodian),
    R.complement(isReadOnly),
    R.both(isAuthorRole, R.anyPass([isOpenPhase, isAuthorPhase]))
])

export const showUpdateCustodianTextfield = R.cond([
    [isClosedPhase, R.F],
    [isReadOnly, R.F],
    [R.both(isAuthorRole, isDualCustodyFlowFull), R.T],
    [R.both(isAdmin, hasNoRoleOnArtifact), R.T],
    [R.T, R.F]
])

export const canUpdateCustodianByPhaseAndRole = R.cond([
    [isLoading, R.F],
    [isReadOnly, R.F],
    [isClosedPhase, R.F],
    [R.both(isAuthorRole, R.anyPass([isOpenPhase, isAuthorPhase])), R.T],
    [R.both(isAdmin, hasNoRoleOnArtifact), R.T],
    [R.T, R.F]
])

export const canSaveCustodian = R.allPass([
    R.complement(isLoading),
    R.complement(isCustodianInputEmpty),
    R.complement(isCustodianEqualToInput),
    isCustodianInputEmailValid,
    isDualCustodyFlowFull,
    canUpdateCustodianByPhaseAndRole
]);