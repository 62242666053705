import * as React from 'react';
import * as R from 'ramda';
import { Stack, IStackProps, IStackTokens } from 'office-ui-fabric-react/lib'
import { ArtifactMetaData } from '../store/app';
import { Header, Sidebar } from '../components';
import { classNames } from '../shared';
import { AppActionTypes, User } from '../store/app';

export interface ILayoutProps
{
    sidebarItems: ArtifactMetaData[]
    loading: boolean
    loadingOperations: string[]
    user: User
}

const Layout: React.FC<ILayoutProps> = ({ sidebarItems, loading, loadingOperations, user, children }) =>
    <Page>
        <Header loading={loading} />
        <Content>
            <Sidebar 
                user={user}
                sidebarItems={sidebarItems} 
                isSidebarLoading={R.contains(AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER, loadingOperations)}
            />
            <Main>{children}</Main>
        </Content>
    </Page>

const Page = (props: IStackProps) => 
    <Stack 
        tokens={stackTokens0} 
        className={classNames.page}
        { ...props }/>

const Content = (props: IStackProps) =>
    <Stack 
        horizontal 
        tokens={stackTokens10} 
        className={classNames.content} 
        { ...props }/>

const Main = (props: IStackProps) =>
    <Stack 
        grow={3} 
        className={classNames.main} 
        { ...props }/>

const stackTokens10: IStackTokens = { childrenGap: 10 };
const stackTokens0: IStackTokens = { childrenGap: 0 };

export default Layout;