import { ITextProps, Text } from 'office-ui-fabric-react';
import * as React from 'react';

const titleTextStyles: ITextProps['styles'] = (p, theme) => ({
  root: {
    display: 'block',
    fontWeight: 600,
    color: theme.palette.neutralPrimary,
    fontSize: 15,
    marginBottom: 15,
    textAlign: 'center'
  }
});

export const TitleText = (p: ITextProps) => <Text styles={titleTextStyles} {...p} />;