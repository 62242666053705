import { Reducer } from 'redux';
import * as R from 'ramda';
import { 
    AppState, 
    AppActionTypes, 
    User,
    AccessRole,
    QuestionNode,
} from './types';

import Questions from '../../MSATS-DCR-questionnaire.json';

const initialUser: User = {
    alias: "",
    email: "",
    role: AccessRole.User,
}

const questionToModel = (node: any): QuestionNode => {
    return {
        answerId: node.AnswerId,
        answer: node.Answer,
        questionId: node.QuestionId,
        question: node.Question,
        answers: node.Answers ? node.Answers.map(questionToModel) : [],
        isText: node.IsText,
        sectionDescription: node.SectionDescription,
        helpLink: node.HelpLink   
    };
}


const initialState: AppState = { 
    user: initialUser,
    artifactIndexes: [],
    loading: false,
    loadingOperations: [],
    error: '',
    questions: questionToModel(Questions)
};

export const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER:
            return { ...state, artifactIndexes: action.payload };
        case AppActionTypes.ADD_LOADING_OPERATION:
            var newLoadingOperations: string[] = R.union([action.payload], state.loadingOperations);
            return { ...state,
                loading: any(newLoadingOperations),
                loadingOperations: newLoadingOperations,
            }
        case AppActionTypes.REMOVE_LOADING_OPERATION:
            var newLoadingOperations: string[] = R.without(action.payload, state.loadingOperations);
            return { ...state,
                loading: any(newLoadingOperations),
                loadingOperations: newLoadingOperations,
            }        
        case AppActionTypes.GET_USER:
            return { ...state, user: action.payload }
        case AppActionTypes.ADD_ERROR:

            return { ...state, error: action.payload }
        case AppActionTypes.REMOVE_ERROR:
            return { ...state, error: '' }
        default:
            return state;
    }
};

const any = R.pipe(R.isEmpty, R.not);

export { reducer as appReducer }