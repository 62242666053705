import { AppActionTypes } from './';
import { userUri, assignedArtifactsUri } from './endpoints';
import { 
    toJson, 
    dispatchFail, 
    enableLoading, 
    rejectOnFailResponse, 
    removeLoadingAction, 
    dispatchActions,
} from '../../utils';
import * as Api from '../../utils/api';

export const getAssignedArtifacts = (): (d: any) => void =>
    (dispatch: any) =>
    enableLoading(dispatch, AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER)
    .chain(assignedArtifactsUri)
    .chain(Api.Get)
    .chain(rejectOnFailResponse)
    .chain(toJson)
    .fork(
        (error: any) => dispatchFail(dispatch, AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER, error), 
        (payload: any) => dispatchActions(dispatch, [
            ({type: AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER, payload}),
            removeLoadingAction(AppActionTypes.GET_ARTIFACTS_ASSIGNED_TO_USER)
        ]));

export const getUser = (): (d: any) => void =>
    (dispatch: any) =>
    enableLoading(dispatch, AppActionTypes.GET_USER)
    .chain(userUri)
    .chain(Api.Get)
    .chain(rejectOnFailResponse)
    .chain(toJson)
    .fork(
        (error: any) => dispatchFail(dispatch, AppActionTypes.GET_USER, error),
        (payload: any) => dispatchActions(dispatch, [
            ({type: AppActionTypes.GET_USER, payload}),
            removeLoadingAction(AppActionTypes.GET_USER)]));

export const removeError = (): (d: any) => void => 
    (dispatch: any) =>
        dispatch({ type: AppActionTypes.REMOVE_ERROR })
