import * as R from 'ramda';
import {
    serviceChangeId,
    serviceChangeInput,
    hasNoRoleOnArtifact,
    isAuthorRole,
    isSecondaryCustodianRole,
    isOpenPhase,
    isAuthorPhase,
    isCustodianPhase,
    isClosedPhase,
    isReadOnly,
    isAdmin,
    isLoading,
} from '.';

const isServiceChangeEqualToInput = (input: any) => R.equals(serviceChangeId(input), serviceChangeInput(input));
const isServiceChangeInputEmpty = (input: any) => R.equals('', serviceChangeInput(input));

export const displayUpdateServiceChangeTextField = R.cond([
    [isClosedPhase, R.F],
    [isReadOnly, R.F],
    [R.either(isAuthorRole, isSecondaryCustodianRole), R.T],
    [R.both(hasNoRoleOnArtifact, isAdmin), R.T],
    [R.T, R.F]
])

export const canUpdateServiceChangeByPhaseAndRole = R.cond([
    [isLoading, R.F],
    [R.both(isAuthorRole, R.either(isOpenPhase, isAuthorPhase)), R.T],
    [R.both(R.both(hasNoRoleOnArtifact, isAdmin), R.anyPass([isOpenPhase, isAuthorPhase, isCustodianPhase])), R.T],
    [R.T, R.F]
]);

export const canSaveServiceChange = R.allPass([
    R.complement(isClosedPhase),
    R.complement(isReadOnly),
    R.complement(isServiceChangeEqualToInput),
    R.complement(isServiceChangeInputEmpty),
    canUpdateServiceChangeByPhaseAndRole
]);