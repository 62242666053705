import * as React from 'react';
import { Label } from 'office-ui-fabric-react/lib';
import {
    StidServiceNameMap
} from '../store/artifact';
import { DropDown, Input } from '../components';
import { QuestionNode } from '../store/app'
import * as R from 'ramda';

export interface IQuestionProps {
    question: QuestionNode
    text: string
    answerId: string
    action: Function
    isDisabled: boolean
    style: string
    index: number
}

export default class extends React.Component<IQuestionProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    render() {
        var answers = [{ answerId: "", answer: "Choose an option ..." }, ...this.props.question.answers];

        var answerList = answers.map(node =>  {
            return { stid: node.answerId, serviceName: node.answer };
        })

        const questionDropDown =
        (
            <div>
                <Label>{this.props.question.question}</Label>
                    <DropDown label="" value={this.props.answerId} errorMessage="" list={answerList} action={(item : any)=> this.props.action(item, this.props)} disable={this.props.isDisabled} />
            </div>
        );

        const questionCustomText = (
            <div>
                <Label>{this.props.question.question}</Label>
                <Input value={this.props.text} required={true} disabled={this.props.isDisabled} onChange={(text: any) => this.props.action(text, this.props)} label="" errorMessage="" ></Input>
            </div>
        );

        return this.props.question.isText ? questionCustomText : questionDropDown;
    }
}