import * as React from 'react';

import {
    JitArtifact,
    StidServiceNameMap
} from '../store/artifact';

interface IDropDownProps
    extends React.DetailedHTMLProps< React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
        value: string
        label: string
        list: StidServiceNameMap[]
        errorMessage: string | JSX.Element
        action: Function
        disable: boolean
    }

interface IDropdownState {
    value: number | string;
}

class DropDown extends React.PureComponent<IDropDownProps, IDropdownState> {
    constructor(props: Readonly<IDropDownProps>) {
        super(props);

        this.state = {
            value: this.props.value,
        }
    }
    onChange(e: { target: { value: any; }; preventDefault: () => void; stopPropagation: () => void; }) {
        this.props.action(e.target.value);
        this.forceUpdate();
    }

    render() {

        return (            
            <div>
                <select title ="DropDown" disabled={this.props.disable} value={this.props.value} style={{ width: "500px" }}
                    onChange={this.onChange.bind(this)}>
                    {this.props.list.map(x => {
                        return <option value={x.stid} key={x.stid} >{x.serviceName}</option>
                    })}               
                </select>
            </div>
        );
    }
}
//
export default DropDown;