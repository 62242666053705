import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { 
    DualCustodyFlowEnum, 
    changeDualCustodyFlow, 
    loadCodeContributorsIntoArtifact,
    updateDualcustodyLiteValidation,
    DualCustodyUserValidation,
    JitArtifact,
    getArtifact,
} from '../../../store/artifact';
import { User } from '../../../store/app';
import { Checkbox } from 'office-ui-fabric-react/lib/';
import { TitleText } from '../../../shared';
import ServiceNameContributorTable from './ServiceNameContributorTable';
import { 
    canUpdateDualCustodyCheckbox,
    canShowCodeContributorContainer,
    isUserCodeContributor,
} from '../../../constants/actionLogic/dualCustody';
import { Stack, MessageBar, MessageBarType, IStackTokens } from 'office-ui-fabric-react';

import {
    codeContributors,
    userAlias
} from '../../../constants/actionLogic/index';

export interface IDualCustodyFlowProps {
    artifact: JitArtifact
    dualCustodyFlow: DualCustodyFlowEnum
    dualCustodyLiteValidation: DualCustodyUserValidation 
    user: User 
    style: string
    isLoading: boolean
}

interface PropsFromDispatch {
    changeDualCustodyFlow: Function
    loadCodeContributorsIntoArtifact: Function
    updateDualcustodyLiteValidation: Function
    getArtifact: Function
}

interface IResetMessageBar {
    resetChoice?: () => void;
}

type DualCustodyProps = IDualCustodyFlowProps & PropsFromDispatch

const DualCustodyFlow: React.FC<DualCustodyProps> = (props: DualCustodyProps) => {
    const [messageBar, setMessageBar] = React.useState<string | undefined>(undefined);
    const resetMessageBar = () => setMessageBar(undefined); 

    const checkUserIsCodeContributor = () =>
        props.loadCodeContributorsIntoArtifact(props.artifact.fullyQualifiedId)
            .then(() => GetUserCodeContributorStatus(props)).catch((error: any) => { return DualCustodyUserValidation.NotValidated; });

    const updateFlow = (dualCustodyFlow: DualCustodyFlowEnum) => {
        props.changeDualCustodyFlow(dualCustodyFlow);
        if (dualCustodyFlow === DualCustodyFlowEnum.Lite) {
            checkUserIsCodeContributor().then((isContributor: DualCustodyUserValidation) => {
                if (isContributor === DualCustodyUserValidation.ValidatedContributor) {
                    props.updateDualcustodyLiteValidation(DualCustodyUserValidation.ValidatedContributor);
                    setMessageBar('blocked');
                } else if (isContributor === DualCustodyUserValidation.ValidatedNotContributor) {
                    props.updateDualcustodyLiteValidation(DualCustodyUserValidation.ValidatedNotContributor);
                    setMessageBar('warning');
                }
                else {
                    // Failed to validate
                    resetMessageBar();
                    props.updateDualcustodyLiteValidation(DualCustodyUserValidation.NotValidated);
                }
            })
        } else {
            // dual custody full
            resetMessageBar();
            props.updateDualcustodyLiteValidation(DualCustodyUserValidation.NotValidated);
        }
    }

    const codeContributorAliases = R.pipe(codeContributors, R.keys);

    const GetUserCodeContributorStatus = (props: any) => {

        if (R.contains(userAlias(props), codeContributorAliases(props)))
            return DualCustodyUserValidation.ValidatedContributor;
        else
            return DualCustodyUserValidation.ValidatedNotContributor;
    }

    const CodeContributorContainer = ( 
        <Stack>
            {(messageBar === 'warning') && <NonContributorNotification resetChoice={resetMessageBar} />}
            {(messageBar === 'blocked') && <ContributorNotification resetChoice={resetMessageBar} />}

            <ServiceNameContributorTable gitContributors={props.artifact.codeContributors} />
        </Stack>);

    return (
        <div className={props.style}>
            <TitleText>Dual Custody Flow</TitleText>
            <Stack tokens={stackTokens20}>
                <Checkbox 
                    label="Check for Dual Custody Lite if you have not contributed to the service in the past 365 days (can take 15 to 45 seconds to load)"
                    checked={props.dualCustodyFlow === DualCustodyFlowEnum.Lite}
                    onChange={(_, isChecked?: boolean) => 
                        isChecked ? 
                        updateFlow(DualCustodyFlowEnum.Lite) : 
                        updateFlow(DualCustodyFlowEnum.Full)
                    }
                    disabled={!canUpdateDualCustodyCheckbox(props)}
                />
                { canShowCodeContributorContainer(props) ? CodeContributorContainer : null }
            </Stack>
        </div>
    );
}

const stackTokens20: IStackTokens = { childrenGap: 20 };

const NonContributorNotification = (p: IResetMessageBar) => (
    <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      onDismiss={p.resetChoice}
      dismissButtonAriaLabel="Close"
    >
      Please verify you are not one of the following contributors
    </MessageBar>
);

const ContributorNotification = (p: IResetMessageBar) => (
    <MessageBar
      messageBarType={MessageBarType.blocked}
      isMultiline={false}
      onDismiss={p.resetChoice}
      dismissButtonAriaLabel="Close"
    >
      Cannot Dual Custody Lite attest because you were found to be a code contributor
    </MessageBar>
);

const mapDispatchToProps: PropsFromDispatch = {
    changeDualCustodyFlow: changeDualCustodyFlow,
    loadCodeContributorsIntoArtifact: loadCodeContributorsIntoArtifact,
    updateDualcustodyLiteValidation: updateDualcustodyLiteValidation,
    getArtifact: getArtifact,
}

export default connect(null, mapDispatchToProps)(DualCustodyFlow);