import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { ActionButton } from '../components';
import {
    AdminCreateTestArtifactContent
} from '../constants';
import {
    adminCreateTestArtifact,
} from '../store/artifact';
import { User } from '../store/app';
import {
    isAdmin,
} from '../constants/actionLogic';

interface IAdminDiagnosticActions {
    user: User
    isLoading: boolean
    style: string
}

interface PropsFromDispatch {
    createNewArtifact: Function
}

enum displayedContainer {
    None,
    All
}

type AdminDiagnosticActionsProps = IAdminDiagnosticActions & PropsFromDispatch;

const AdminDiagnosticActions: React.FC<AdminDiagnosticActionsProps> = (props) => {
    const getContainer = (containerEnum: displayedContainer) => {
        switch (containerEnum) {
            case displayedContainer.None:
                return null;
            case displayedContainer.All:
                return CreateTestArtifactButton;
            default:
                return null;
        }
    };

    const noTooltips: string[] = []
    const CreateTestArtifactButton = (
        <ActionButton
            style={props.style}
            buttonText="Create Test Artifact"
            buttonIcon="Send"
            confirmationTitle="Create Test Artifact"
            confirmationBody={AdminCreateTestArtifactContent}
            confirmationButtonText="Send"
            tooltipContent={noTooltips}
            callback={() => props.createNewArtifact()}
            isDisabled={false}
        />
    );


    const displayedContainerEnum = getContainerEnum(props);
    const container = getContainer(displayedContainerEnum);

    return (<div>{container}</div>);
}

const getContainerEnum = R.cond([
    [isAdmin, R.always(displayedContainer.All)]
]);

const mapDispatchToProps: PropsFromDispatch = {
    createNewArtifact: adminCreateTestArtifact
}

export default connect(null, mapDispatchToProps)(AdminDiagnosticActions);
