import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { ActionButton } from '../../components';
import { 
    SendArtifactForReviewContent,  
    RecallArtifactContent,
    CustodianAttestArtifactContent,
    SecondaryAttestArtifactContent,
    AdminCloseArtifactContent,
    AdminToAuthorArtifactContent,
    AuthorDualCustodyLiteAttestContent,
} from '../../constants';
import { canAttest, attestMissingRequirements } from '../../constants/actionLogic/attest';
import { canSendForReview, sendForReviewMissingRequirements } from '../../constants/actionLogic/sendForReview';
import { canAdminClose } from '../../constants/actionLogic/adminClose';
import { canAdminChangeToAuthor } from '../../constants/actionLogic/adminChangeToAuthor';
import { 
    JitArtifact, 
    attest, 
    sendArtifactForReview, 
    recallArtifact, 
    adminCloseArtifact,
    adminChangePhaseToAuthor,
    AssignedArtifactRole, 
    DualCustodyFlowEnum,
    DualCustodyUserValidation
} from '../../store/artifact';
import { User } from '../../store/app';
import { 
    isAdmin, 
    hasNoRoleOnArtifact, 
    isAuthorRole, 
    isDualCustodyFlowLite, 
    isOpenPhase, 
    isAuthorPhase, 
    isCustodianPhase, 
    isCustodianRole, 
    isSecondaryCustodianRole, 
    isClosedPhase,
    isReadOnly
} from '../../constants/actionLogic';

interface IArtifactStateChange {
    artifact: JitArtifact
    user: User
    isLoading: boolean
    dualCustodyFlow: DualCustodyFlowEnum
    dualCustodyLiteValidation: DualCustodyUserValidation 
    style: string
    questionaireAnswers: any
    questionaireIsComplete: boolean
}

interface PropsFromDispatch {
    attest: Function 
    sendArtifactForReview: Function 
    recallArtifact: Function 
    adminCloseArtifact: Function
    adminChangePhaseToAuthor: Function
}

enum displayedContainer {
    None,
    SendForReview,
    Recall,
    Attest,
    AdminClose,
    AdminAuthor
}

type ArtifactStateChangeProps = IArtifactStateChange & PropsFromDispatch;

const ArtifactStateChange: React.FC<ArtifactStateChangeProps> = (props) => {
    const getContainer = (containerEnum: displayedContainer) => {
        switch (containerEnum) {
            case displayedContainer.None:
                return null;
            case displayedContainer.SendForReview:
                return SendForReviewContainer();
            case displayedContainer.Recall:
                return RecallArtifactContainer;
            case displayedContainer.Attest:
                return AttestContainer();
            case displayedContainer.AdminClose:
                return AdminContainer;
            case displayedContainer.AdminAuthor:
                return AdminSetToAuthorContainer;
            default:
                return null
        }
    }   

    const noTooltips: string[] = []
    const SendForReviewContainer = () => (
        <ActionButton 
            style={props.style}
            buttonText="Send For Review"
            buttonIcon="Send"
            confirmationTitle="Send For Review"
            confirmationBody={SendArtifactForReviewContent}
            confirmationButtonText="Send"
            tooltipContent={sendForReviewMissingRequirements(props)}
            callback={() => props.sendArtifactForReview(props.artifact.fullyQualifiedId, props.questionaireAnswers)}
            isDisabled={!canSendForReview(props) || !props.questionaireIsComplete}
        />
    );
                            
    const RecallArtifactContainer = (
        <ActionButton 
            style={props.style}
            buttonText="Recall Artifact"
            buttonIcon="Undo"
            confirmationTitle="Recall Artifact"
            confirmationBody={RecallArtifactContent}
            confirmationButtonText="Recall"
            tooltipContent={noTooltips}
            callback={() => props.recallArtifact(props.artifact.fullyQualifiedId)}
            isDisabled={props.isLoading}
        />
    );

    const AttestContainer = () => (
            <ActionButton 
                style={props.style}
                buttonText="Attest"
                buttonIcon="SkypeCheck"
                confirmationTitle="Attest Artifact"
                confirmationBody={getAttestPopupMessage(props.artifact.role, props.dualCustodyFlow)}
                confirmationButtonText="Attest"
                tooltipContent={attestMissingRequirements(props)}
                callback={() =>  props.attest(props.artifact.fullyQualifiedId, props.dualCustodyFlow)}
                isDisabled={!canAttest(props)}
            />
    );

    const AdminContainer = (
        <ActionButton
            style={props.style}
            buttonText="Admin Close"
            buttonIcon="ChromeCancel"
            confirmationTitle="Admin Close Artifact"
            confirmationBody={AdminCloseArtifactContent}
            confirmationButtonText="Admin Close"
            tooltipContent={noTooltips}
            callback={() => props.adminCloseArtifact(props.artifact.fullyQualifiedId, props.questionaireAnswers)}
            isDisabled={!canAdminClose(props) || !props.questionaireIsComplete}
        />
    );

    const AdminSetToAuthorContainer = (
        <ActionButton
            style={props.style}
            buttonText="Admin Phase To Author"
            buttonIcon="ChromeCancel"
            confirmationTitle="Admin Phase To Author Artifact"
            confirmationBody={AdminToAuthorArtifactContent}
            confirmationButtonText="Admin To Author"
            tooltipContent={noTooltips}
            callback={() => props.adminChangePhaseToAuthor(props.artifact.fullyQualifiedId)}
            isDisabled={!canAdminChangeToAuthor(props)}
        />
    );

    const displayedContainerEnum = getContainerEnum(props);
    const container = getContainer(displayedContainerEnum);

    const adminPhaseAuthorEnum = getShowAdminContainerEnum(props);
    const containerAdmin = getContainer(adminPhaseAuthorEnum);

    return (<div>{container}{containerAdmin}</div>);
}

// Verify if any changes required for readonly
const getContainerEnum = R.cond([
        [isClosedPhase, R.always(displayedContainer.None)],
        [isReadOnly, R.always(displayedContainer.None)],
        [R.both(isAuthorRole, isDualCustodyFlowLite), R.always(displayedContainer.Attest)],
        [R.both(isAuthorRole, R.anyPass([isOpenPhase, isAuthorPhase])), R.always(displayedContainer.SendForReview)],
        [R.both(isAuthorRole, isCustodianPhase), R.always(displayedContainer.Recall)],
        [isCustodianRole, R.always(displayedContainer.Attest)],
        [R.both(isAdmin, hasNoRoleOnArtifact), R.always(displayedContainer.AdminClose)],
        [isAdmin, R.always(displayedContainer.AdminAuthor)]
    ]);

const getShowAdminContainerEnum = R.cond([
    [R.both(isAdmin, isOpenPhase), R.always(displayedContainer.AdminAuthor)]
]);

const getAttestPopupMessage = (role: AssignedArtifactRole, dualCustodyFlow:DualCustodyFlowEnum) => {
    if (dualCustodyFlow === DualCustodyFlowEnum.Lite){
        return <div/>;
    }

    switch (role) {
        case AssignedArtifactRole.Author:
            return AuthorDualCustodyLiteAttestContent;
        case AssignedArtifactRole.Custodian:
            return CustodianAttestArtifactContent;
        // Keeping this for future
        case AssignedArtifactRole.SecondaryCustodian:
            return SecondaryAttestArtifactContent;
        default:
            return <div/>;
    } 
}

const mapDispatchToProps: PropsFromDispatch = {
    attest: attest,
    sendArtifactForReview: sendArtifactForReview,
    recallArtifact: recallArtifact,
    adminCloseArtifact: adminCloseArtifact,
    adminChangePhaseToAuthor: adminChangePhaseToAuthor
}

export default connect(null, mapDispatchToProps)(ArtifactStateChange);
