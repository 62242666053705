import * as R from 'ramda';
import {
    isAuthorRole,
    isCustodianRole,
    isAuthorPhase,
    isCustodianPhase,
    hasServiceChange,
    hasIcmIncident,
    hasCustodian,
    isLoading,
    isDualCustodyFlowFull,
    isDualCustodyFlowLite,
    isValidatedNotCodeContributor,
} from '.';

const canDualCustodyFullAttest = R.cond([
    [R.both(isCustodianRole, R.allPass([isCustodianPhase, R.either(hasServiceChange, hasIcmIncident) , hasCustodian])), R.T],
    [R.T, R.F]
]);

const canDualCustodyLiteAttest = R.cond([
    [R.both(isAuthorRole, R.allPass([isAuthorPhase, R.either(hasServiceChange, hasIcmIncident), isValidatedNotCodeContributor])), R.T],
    [R.T, R.F]
]);

const canAttestByFlowType = R.cond([
    [R.both(isDualCustodyFlowFull, canDualCustodyFullAttest), R.T],
    [R.both(isDualCustodyFlowLite, canDualCustodyLiteAttest), R.T],
    [R.T, R.F],
]);

export const canAttest = R.allPass([
    R.complement(isLoading),
    canAttestByFlowType
]);

export const attestMissingRequirements = R.ifElse(
    R.complement(isCustodianPhase), 
    () => ['Artifact not sent for review'], 
    () => []);