import * as React from 'react';
import { Text, Stack, Link } from 'office-ui-fabric-react/lib';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { IcM } from '../../../store/artifact';

interface IIcMProps {
    incident: IcM
}

export const IcmIncidentRequest: React.FC<IIcMProps> = ({ incident }) =>
    <Stack style={{marginTop: 20, borderStyle: 'solid', borderWidth: 2, borderColor: NeutralColors.gray80, padding: 15}}>
        <Text style={{ fontWeight: 500 }} variant="medium">Incident Id: <Link href={`https://portal.microsofticm.com/imp/v3/incidents/details/${incident.id}/home`} target="_blank" rel="noopener noreferrer">{incident.id}</Link></Text>
        <br/>
        <Text style={{ fontWeight: 500 }} variant="medium">Title: {incident.title}</Text>
        <br/>
        <Text style={{ fontWeight: 500 }} variant="medium">Current State: {incident.state}</Text>
        <br/>
        <Text style={{ fontWeight: 500 }} variant="medium">Created Date: {incident.createdDate}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">Mitigated Date: {incident.mitigatedDate}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">Mitigation: {incident.mitigation}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">MitigatedBy: {incident.mitigatedBy}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">Resolved Date: {incident.resolvedDate}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">ResolvedBy: {incident.resolvedBy}</Text>
        <br />
        <Text style={{ fontWeight: 500 }} variant="medium">IncidentType: {incident.incidentType}</Text>
    </Stack>