import * as React from 'react';
import { Stack, Text, ITextStyles, Label, FontWeights } from 'office-ui-fabric-react/lib/';

const popupWarningStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.bold
    }
};

const popupBodyStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.regular
    }
};

export const SendArtifactForReviewContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Warning</Text>
        <Label>You are about to send this information to the custodian for attestation. Please verify that:</Label>
        <Text variant="medium" styles={popupBodyStyles}>
            <ul>
                <li>The associated Service Change/Icm is attached and fully descriptive of actions taken during the JIT session.</li>
                <li>All of your production access has been revoked to the service you JIT’d in.</li>
                <li>All remote desktop / interactive login sessions have been closed. 
                    If using XTS, verify access has been revoked via AzureJIT portal, and XTS has been closed.</li>
            </ul>
        </Text>
    </Stack>
);

export const RecallArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Warning</Text>
        <Label>You are about to recall this attestation.</Label>
        <Text variant="medium" styles={popupBodyStyles}>
            <ul>
                <li>This will prevent the reviewer from attesting.</li>
                <li>Reviewer will be notified when recalled.</li>
            </ul>
        </Text>
    </Stack>
);

export const CustodianAttestArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Warning</Text>
        <Label>You are about to attest to this information. Please verify that:</Label>
        <Text variant="medium" styles={popupBodyStyles}>
            <ul>
                <li>The author has completely revoked their JIT access.</li>
                <li>All remote desktop / interactive login sessions have been closed. If using XTS, verify access has been revoked via AzureJIT portal, and XTS has been closed.</li>
                <li>All actions taken by the author during the JIT session are described completely in the service change/Icm.</li>
                <li>You observed all actions documented during the JIT session.</li>
            </ul>
        </Text>
    </Stack>
);

export const SecondaryAttestArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Warning</Text>
        <Label>You are about to attest to this information. Please verify that:</Label>
        <Text variant="medium" styles={popupBodyStyles}>
            <ul>
                <li>YOU SHOULD ONLY DO THIS IF THE ORIGINAL AUTHOR/CUSTODIAN ARE UNABLE TO COMPLETE THIS PROCESS THEMSELVES.</li>
                <li>Confirm that a service change/Icm describing all actions taken by the author and/or all forensic activities undertaken as part of a remediation process has been associated with this JIT session.</li>
            </ul>
        </Text>
    </Stack>
);

export const AdminCloseArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Are you sure you would like to Admin Close this artifact?</Text>
    </Stack>
);

export const AdminToAuthorArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Are you sure you would like to change the phase to Author for this artifact?</Text>
    </Stack>
);

export const AdminCreateTestArtifactContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Are you sure you want to create a test artifact.</Text>
    </Stack>
);

export const AuthorDualCustodyLiteAttestContent = (
    <Stack>
        <Text variant="mediumPlus" styles={popupWarningStyles}>Are you sure you would like to self attest this artifact?</Text>
    </Stack>
);