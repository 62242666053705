import * as R from 'ramda';
import { 
    isLoading, 
    isOpenPhase,
    isAuthorPhase, 
    isAuthorRole, 
    hasServiceChange,
    hasIcmIncident,
    hasCustodian,
} from '.';
import { removeEmptyStrings } from '../../constants';



export const serviceChangeRequirements = R.allPass([
    R.complement(isLoading),
    isAuthorRole,
    isAuthorPhase,
    hasServiceChange,
    hasCustodian
]);

export const icmRequirements = R.allPass([
    R.complement(isLoading),
    isAuthorRole,
    isAuthorPhase,
    hasIcmIncident,
    hasCustodian
]);

export const canSendForReview = R.anyPass([
    serviceChangeRequirements,
    icmRequirements
]);

const revokedRequirement = R.ifElse(isOpenPhase, () => "Jit session not revoked", () => ''); 
const hasServiceChangeRequirement = R.ifElse(R.complement(R.either(hasServiceChange, hasIcmIncident)), () => "Service Change/Icm Incident required", () => '');
const hasCustodianRequirement = R.ifElse(R.complement(hasCustodian), () => "Custodian required", () => '');
const hasIcmRequirement = R.ifElse(R.complement(hasIcmIncident), () => "Icm Incident required", () => '');

const getRequirements = (props: any) => R.juxt([
    revokedRequirement, 
    hasServiceChangeRequirement,
    hasCustodianRequirement
])(props);

export const sendForReviewMissingRequirements = (props: any) => R.pipe(getRequirements, removeEmptyStrings)(props);