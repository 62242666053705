import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib';

interface IInputProps {
    value: string
    onChange: Function
    label: string
    required: boolean
    disabled: boolean
    errorMessage: string | JSX.Element 
}

const Input: React.FC<IInputProps> = (props) => {
    const changeStateHandler = (e: React.FormEvent) => {
        var target = e.target as HTMLSelectElement;
        props.onChange(target.value);
    }

    return (
        <TextField 
            value={props.value} 
            onChange={changeStateHandler}
            label={props.label} 
            required={props.required}
            disabled={props.disabled}
            errorMessage={props.errorMessage}
            title = "Input"
        />
    );
}

export default Input;