import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Attestation from './containers/ArtifactAttestation';
import history from './utils/history'; 

const Routes: React.FC = () => (
    <Router history={history}>
        <Switch>
            <Route component={Attestation} />
        </Switch>
    </Router>
)

export default Routes;