import { ArtifactPhase, AssignedArtifactRole } from '../artifact';

export interface ArtifactMetaData {
    id: string
    fullyQualifiedId: string
    source: string
    role: AssignedArtifactRole
    phase: ArtifactPhase
    jitStartTime: string
    author: string
}

export interface LoadingOperationPayload {
    loading: boolean
    loadingOperationSet: Set<string>
}

export enum AccessRole {
    User,
    Admin
}

export interface User {
    alias: string
    email: string
    role: AccessRole
}

export interface QuestionNode {
    questionId: string
    question: string
    answerId: string
    answer: string
    isText: boolean
    answers: QuestionNode[]
    sectionDescription: string
    helpLink: string
}

export enum AppActionTypes { 
    GET_ARTIFACTS_ASSIGNED_TO_USER = '@@app/GET_ARTIFACTS_ASSIGNED_TO_USER',
    ADD_LOADING_OPERATION = '@@app/ADD_LOADING_OPERATION',
    REMOVE_LOADING_OPERATION = '@@app/REMOVE_LOADING_OPERATION',
    GET_USER = '@@app/GET_USER',
    ADD_ERROR = '@@app/ADD_ERROR',
    REMOVE_ERROR = '@@app/REMOVE_ERROR',
    GET_QUESTION = '@@app/GET_QUESTION'
}

export interface Action { 
    type: string
    payload: any
}

export interface AppState {
    user: User
    artifactIndexes: ArtifactMetaData[]
    loading: boolean
    loadingOperations: string[]
    error: string
    questions: QuestionNode
}