import { encase } from 'fluture';

export const getArtifactUri = (fullyQualifiedId: string) => encase(() => `artifact/getArtifact/${fullyQualifiedId}`);

export const getEventsUri = (fullyQualifiedId: string) => encase(() => `artifact/getEvents/${fullyQualifiedId}`);

export const attestUri = 'artifact/attest';

export const updateCustodianUri = 'artifact/updateCustodian';

export const tryUpdateServiceChangeUri = 'artifact/tryUpdateServiceChange';

export const tryUpdateIcmUri = 'artifact/tryUpdateIcm';

export const updateServiceUri = 'artifact/updateService';

export const sendArtifactForReviewUri = (fullyQualifiedId: string) => `artifact/sendForReview/${fullyQualifiedId}`;

export const recallArtifactUri = (fullyQualifiedId: string) => encase(() => `artifact/recall/${fullyQualifiedId}`);

export const adminCloseArtifactUri = (fullyQualifiedId: string) => encase(() => `artifact/adminClose/${fullyQualifiedId}`);

export const adminCloseArtifactUriWithBody = (fullyQualifiedId: string) => `artifact/adminClose/${fullyQualifiedId}`;

export const adminChangePhaseToAuthorUri = (fullyQualifiedId: string) => encase(() => `artifact/adminChangePhaseToAuthor/${fullyQualifiedId}`);

export const adminCreateTestArtifactUri = (phase: string) => encase(() => `artifact/createTestArtifact`);

export const loadCodeContributorsIntoArtifactUri = (fullyQualifiedId: string) => encase(() => `artifact/loadCodeContributorsIntoArtifact/${fullyQualifiedId}`);
