import * as React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/';
import { TitleText } from '../../shared';
import { GetStatusFromPhase } from '../../constants';
import { JitArtifact } from '../../store/artifact';
import { tryGetOutOfScopeTimeOrDefault, tryGetPercentCompleteOrDefault } from '../../constants/actionLogic';

interface IPhaseProps {
    artifact: JitArtifact
    style: string
}

export const Phase: React.FC<IPhaseProps> = (props) => {
    return (
        <div className={props.style}>
            <TitleText>Phase</TitleText>
            <ProgressIndicator 
                title = "Progress Indicator"
                label={`Current Phase: ${GetStatusFromPhase(props.artifact.phase)}`}
                description={`Time Remaining: ${tryGetOutOfScopeTimeOrDefault(props)}`} 
                percentComplete={tryGetPercentCompleteOrDefault(props)} 
                barHeight={5} 
                styles={{ progressBar: { backgroundColor: tryGetPercentCompleteOrDefault(props) >= 1 ? 'red' : 'green'}}}
            />
        </div>
    );
}