import * as R from 'ramda';
import { 
    isAuthorRole, 
    isSecondaryCustodianRole, 
    isOpenPhase, 
    isAuthorPhase, 
    isClosedPhase,
    isReadOnly,
    isLoading,
    isDualCustodyFlowLite,
    isUnvalidatedCodeContributor,
    codeContributors,
    userAlias,
} from '.';
import { objectHasKeys } from '../helperFunctions';

const codeContributorAliases = R.pipe(codeContributors, R.keys);
export const isUserCodeContributor = (props: any) => R.contains(userAlias(props), codeContributorAliases(props));
export const hasCodeContributors = objectHasKeys;
export const canUpdateDualCustodyCheckbox = R.cond([
    [isLoading, R.F],
    [isReadOnly, R.F],
    [isClosedPhase, R.F],
    [R.both(isAuthorRole, R.either(isOpenPhase, isAuthorPhase)), R.T],
    [isSecondaryCustodianRole, R.T],
    [R.T, R.F]
]);

export const canShowCodeContributorContainer = R.allPass([
    isDualCustodyFlowLite,
    R.complement(isUnvalidatedCodeContributor),
    hasCodeContributors,
]);

