import { ArtifactPhase } from '../store/artifact';

export const GetStatusFromPhase = (phase: ArtifactPhase) => {
    switch (phase) {
        case ArtifactPhase.Open:
            return "Open";
        case ArtifactPhase.Author:
            return "Waiting On Author";
        case ArtifactPhase.Custodian:
            return "Waiting For Attestation";
        case ArtifactPhase.Closed:
            return "Closed";
        case ArtifactPhase.ManualAdminClosed:
            return "ManualAdminClosed";
        case ArtifactPhase.ReadOnly:
            return "ReadOnly";
        case ArtifactPhase.AttestationNotRequired:
            return "Attestation Not Required";
        case ArtifactPhase.Staging:
            return "Staging";
        default:
            return "";
    }
}