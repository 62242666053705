import * as React from 'react';
import { Text, Stack, Link } from 'office-ui-fabric-react/lib';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { ServiceChange } from '../../../store/artifact';

interface IServiceChangeProps {
    serviceChange: ServiceChange
}

export const ServiceChangeRequest: React.FC<IServiceChangeProps> = ({ serviceChange }) =>
    <Stack style={{marginTop: 20, borderStyle: 'solid', borderWidth: 2, borderColor: NeutralColors.gray80, padding: 15}}>
        <Text style={{fontWeight: 500}} variant="medium">Service Change: <Link href={`https://microsoft.visualstudio.com/OSGS/_workitems/edit/${serviceChange.id}/`}target="_blank" rel="noopener noreferrer">{serviceChange.id}</Link></Text>
        <br/>
        <Text style={{fontWeight: 500}} variant="medium">Title: {serviceChange.fields.title}</Text>
        <br/>
        <Text style={{fontWeight: 500}} variant="medium">State: {serviceChange.fields.state}</Text>
        <br/>
        <Text style={{fontWeight: 500}} variant="medium">Created Date: {serviceChange.fields.createdDate}</Text>
        <br/>
        <Text style={{fontWeight: 500}} variant="medium">Technical Approver: { serviceChange.fields.technicalApprover ? serviceChange.fields.technicalApprover.displayName : ''}</Text>
        <br/>
        <Text style={{fontWeight: 500}} variant="medium">Deployment Approver: { serviceChange.fields.deploymentApprover ? serviceChange.fields.deploymentApprover.displayName : ''}</Text>
    </Stack>