import { Dictionary } from "ramda";

export enum ArtifactPhase {
    Open = 'Open',
    Author = 'Author',
    Custodian = 'Custodian',
    Closed = 'Closed',
    ManualAdminClosed = 'ManualAdminClosed',
    ReadOnly = 'ReadOnly',
    AttestationNotRequired = 'AttestationNotRequired',
    Staging = 'Staging',
}

export enum DualCustodyUserValidation {
    NotValidated,
    ValidatedContributor,
    ValidatedNotContributor,
}

export enum DualCustodyFlowEnum {
    Full = 'Full',
    Lite = 'Lite',
}

export enum AssignedArtifactRole {
    Author = 'Author',
    Custodian = 'Custodian',
    SecondaryCustodian = 'SecondaryCustodian',
    None = 'None',
}

export interface ContentTimestampTuple {
    content: string
    timestamp: string
}

export interface JitTimeline {
    startTime: string
    outOfScopeTime: string
    percentComplete: number
    timelineEvents: ContentTimestampTuple[]
}

export interface ServiceChangeApprover {
    displayName: string
    uniqueName: string
}

export interface ServiceChangeField {
    id: string
    state: string
    createdDate: string
    closedDate: string
    description: string
    title: string
    technicalApprover: ServiceChangeApprover
    deploymentApprover: ServiceChangeApprover
}

export interface ServiceChange {
  id: string
  fields: ServiceChangeField
}

export interface IcM {
    id: string
    state: string
    title: string
    createdDate: string
    mitigatedDate: string
    mitigation: string
    mitigatedBy: string
    resolvedDate: string
    resolvedBy: string
    incidentType: string
}

export interface GitContributor {
    email: string
    displayName: string
}

export interface GitRepoContributor {
    email: string
    displayName: string
}

export interface GitContributorToRepos {
    contributor: GitRepoContributor
    repositories: string[]
}

export interface CodeContributorMap {
    [contributorAlias: string]: GitContributorToRepos
}

export interface StidServiceNameMap {
    stid: string
    serviceName: string
}

export interface QuestionaireAnswer {
    answerId: string
    questionId: string
    text: string
    level: number
}

export interface JitArtifact {
    fullyQualifiedId: string
    role: AssignedArtifactRole
    phase: ArtifactPhase 
    author: string
    authorName: string
    custodian: string
    custodianName: string
    secondaryCustodians: string[]
    icM?: IcM
    serviceChange?: ServiceChange
    timeline?: JitTimeline
    codeContributors: CodeContributorMap
    mappedServices: StidServiceNameMap[]
    serviceName: string
    serviceTree: string
    questionaireAnswers: QuestionaireAnswer[]
}

export enum ArtifactActionTypes { 
    GET_ARTIFACT = '@@artifact/GET_ARTIFACT',
    GET_ARTIFACT_EVENT = '@@artifact/GET_ARTIFACT_EVENT',
    ATTEST = '@@artifact/ATTEST',
    UPDATE_CUSTODIAN = '@@artifact/UPDATE_CUSTODIAN',
    TRY_UPDATE_SERVICE_CHANGE = '@@artifact/TRY_UPDATE_SERVICE_CHANGE',
    TRY_UPDATE_ICM = '@@artifact/TRY_UPDATE_ICM',
    UPDATE_SERVICE = '@@artifact/UPDATE_SERVICE',
    CLEAR_ARTIFACT = '@@artifact/CLEAR_ARTIFACT',
    SEND_ARTIFACT_FOR_REVIEW = '@@artifact/SEND_ARTIFACT_FOR_REVIEW',
    RECALL_ARTIFACT = '@@artifact/RECALL_ARTIFACT',
    ADMIN_CLOSE_ARTIFACT = '@@artifact/ADMIN_CLOSE_ARTIFACT',
    ADMIN_PHASE_TO_AUTHOR = '@@artifact/ADMIN_PHASE_TO_AUTHOR',
    ADMIN_CREATE_TEST_ARTIFACT = '@@artifact/ADMIN_CREATE_TEST_ARTIFACT',
    SET_DUAL_CUSTODY_FLOW = '@@artifact/SET_DUAL_CUSTODY_FLOW',
    LOAD_CODE_CONTRIBUTORS_INTO_ARTIFACT = '@@artifact/LOAD_CODE_CONTRIBUTORS_INTO_ARTIFACT',
    UPDATE_DUAL_CUSTODY_LITE_VALIDATION = '@@artifact/UPDATE_DUAL_CUSTODY_LITE_VALIDATION'
}

export interface ArtifactState {
    artifact?: JitArtifact,
    artifactEvents?: any,
    dualCustodyFlow: DualCustodyFlowEnum,
    dualCustodyLiteValidation: DualCustodyUserValidation
}