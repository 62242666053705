import * as React from 'react';
import { Stack, ProgressIndicator, Text } from 'office-ui-fabric-react/lib';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { classNames } from '../shared';
import logo from '../images/MicrosoftLogo.png'
export interface IHeaderProps {
    loading: boolean
}

const Header: React.FC<IHeaderProps> = ({ loading }) => 
    <div>
        <Stack horizontal verticalAlign="center" grow={0} className={classNames.header}>
            <Stack horizontal grow={1} verticalAlign="center">
                <div className={classNames.microsoftLogo}>
                    <img src={ logo } className={classNames.microsoftLogo} alt="" />
                </div>
                <Text
                    id="site_title"
                    aria-label="Attestation Service"
                    className={classNames.linkTitle}
                    >
                    | Attestation Service
                </Text>
            </Stack>
            <Stack horizontal grow={1} verticalAlign="center" horizontalAlign="end">
                <Text
                    id="site_title"
                    aria-label="Privacy"
                    className={classNames.linkTitle}
                > <p><a href="https://go.microsoft.com/fwlink/?LinkId=518021" target="_blank">Microsoft Data Privacy Notice</a></p>
                </Text>
            </Stack>
        </Stack>
        <div style={{backgroundColor: NeutralColors.white}}>
            {loading ? <ProgressIndicator />
            : <div style={{position: 'relative', overflow: 'hidden', height: '2px', paddingTop: '8px', paddingRight: '8px', paddingBottom: '8px', paddingLeft: '0px'}} />}
        </div>
    </div>

export default Header;