import * as React from 'react';
import { 
    Dialog, 
    DialogFooter, 
    DialogType,
    Text,
    PrimaryButton,
} from 'office-ui-fabric-react/lib/';

interface IErrorPopupProps {
    isOpen: boolean
    close: Function
    error: string
}

export const ErrorPopup: React.FC<IErrorPopupProps> = ({ isOpen, close, error }) =>
    <Dialog 
        hidden={!isOpen} 
        onDismiss={() => close()} 
        dialogContentProps={{
            type: DialogType.close,
            title: 'Failed to perform action',
        }}
        minWidth={350}
        modalProps={{ isBlocking: false, styles: { main: { maxWidth: 750 } }, }}
    >
        <Text>{ error }</Text>
        <br/><br/>
        <Text>
            If this continues to occur, please contact msatsadmins@microsoft.com
        </Text>
        <DialogFooter>
            <PrimaryButton 
                onClick={() => close()} 
                text="ok" 
            />
        </DialogFooter>
    </Dialog>

export default ErrorPopup;