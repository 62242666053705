import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Stack } from 'office-ui-fabric-react/lib/';
import { 
    JitArtifact, 
    DualCustodyFlowEnum,
    getArtifact,
    attest,
    tryUpdateServiceChange,
    tryUpdateIcm,
    updateServiceAction,
    updateCustodian,
    sendArtifactForReview,
    recallArtifact,
    adminCloseArtifact,
    adminChangePhaseToAuthor,
    loadCodeContributorsIntoArtifact,
    clearArtifact,
    changeDualCustodyFlow,
    DualCustodyUserValidation,
    getEvents,
    ArtifactPhase
} from '../store/artifact';
import { QuestionNode, User } from '../store/app';
import { ApplicationState } from '../store';
import { classNames } from '../shared';
import { history } from '../utils';
import { 
    Documentation, 
    Roles, 
    ArtifactStateChange, 
    DualCustodyFlow,
    Phase,
    JitEvents,
    ServiceSelection,
    QuestionaireSelection,
    NoAction
} from '.';
import {
    beginsWithUrlHash, 
    dualCustodyFlow as dualCustodyFlowValue, 
    fullyQualifiedId 
} from '../constants';

interface PropsFromState {
    user: User
    questions: QuestionNode
    artifact?: JitArtifact
    artifactEvents?: any
    isLoading: boolean
    dualCustodyFlow: DualCustodyFlowEnum
    dualCustodyLiteValidation: DualCustodyUserValidation
}

interface PropsFromDispatch {
    getArtifact: Function
    attest: Function
    tryUpdateServiceChange: Function
    tryUpdateIcm: Function
    updateService: Function
    updateCustodian: Function
    sendArtifactForReview: Function
    recallArtifact: Function
    adminCloseArtifact: Function
    adminChangePhaseToAuthor: Function
    loadCodeContributorsIntoArtifact: Function
    clearArtifact: Function
    changeDualCustodyFlow: Function
    getEvents: Function
}

interface AttestationFields {
    currentUrl: string
    questionaireIsComplete: boolean
    questionaireAnswers: any
}

const convertToQuestionaireAnswerNode = (item: any) => {
    return {answerId: item.answerId, questionId: item.question.questionId, text: item.text, level: item.index };
}
type AttestationProps = PropsFromState & PropsFromDispatch;

class ArtifactAttestation extends React.Component<AttestationProps, AttestationFields> {

    state = {
        currentUrl: '',
        questionaireIsComplete: false,
        questionaireAnswers: []
    };

    constructor(props: any, context: any){
        super(props, context);
    }

    componentDidUpdate() {
        const url = getUrl(history);
        if (!isValidUrl(url)) {  // if url is '' or '#/' 
            this.props.clearArtifact(); // not valid (display empty page)
            return;
        }

        if (R.not(R.equals(url, this.state.currentUrl))) { // changed url
            this.setState({ currentUrl: url }); // update to new url
            this.getArtifact(url) // load new artifact
        }
    }

    handleQuestionaireSelection = (isCompleted: boolean, answers: any) => {
        this.setState({ questionaireIsComplete: isCompleted });
        this.setState({ questionaireAnswers: answers.map(convertToQuestionaireAnswerNode)});
    }

    getArtifact = (url:string) => this.props.getArtifact(convertUrlToFqid(url));
    public render() {
        if (this.props.artifact){

            const curriedUpdateCustodian = R.curry((custodianEmail: string) => 
                this.props.updateCustodian(fullyQualifiedId(this.props), custodianEmail)
            );

            const curriedUpdateService = R.curry((serviceId: string) =>
                this.props.updateService(fullyQualifiedId(this.props), serviceId)
            );

            const curriedTryUpdateServiceChange = R.curry((serviceChangeId: string) =>
                this.props.tryUpdateServiceChange(fullyQualifiedId(this.props), serviceChangeId, dualCustodyFlowValue(this.props))
            );

            const curriedTryUpdateIcm = R.curry((incidentId: string) =>
                this.props.tryUpdateIcm(fullyQualifiedId(this.props), incidentId, dualCustodyFlowValue(this.props))
            );


            const refreshArtifact = () => this.props.getArtifact(fullyQualifiedId(this.props));

            
            return (
                <Stack className={classNames.attestation}>

                    { this.props.artifact.phase === ArtifactPhase.ReadOnly || this.props.artifact.phase === ArtifactPhase.AttestationNotRequired || this.props.artifact.phase === ArtifactPhase.Staging ?
                        <NoAction artifact={this.props.artifact} style={classNames.attestationBlock} />
                        : <div />
                    }

                    <Phase 
                        artifact={this.props.artifact}
                        style={classNames.attestationBlock}
                    />

                    <JitEvents 
                        artifact={this.props.artifact}
                        isLoading={this.props.isLoading}
                        style={classNames.attestationBlock}
                        artifactEvents= {this.props.artifactEvents}
                    />

                    <DualCustodyFlow
                        artifact={this.props.artifact}
                        dualCustodyFlow={this.props.dualCustodyFlow}
                        dualCustodyLiteValidation={this.props.dualCustodyLiteValidation}
                        user={this.props.user}
                        style={classNames.attestationBlock}
                        isLoading={this.props.isLoading}
                    />

                    <ServiceSelection
                        artifact={this.props.artifact}
                        updateService={curriedUpdateService}
                        style={classNames.attestationBlock}
                    />

                    <Roles
                        artifact={this.props.artifact}
                        updateCustodian={curriedUpdateCustodian}
                        isLoading={this.props.isLoading}
                        user={this.props.user}
                        dualCustodyFlow={this.props.dualCustodyFlow}
                        style={classNames.attestationBlock}
                    />

                    <Documentation
                        artifact={this.props.artifact}
                        tryUpdateServiceChange={curriedTryUpdateServiceChange}
                        tryUpdateIcm={curriedTryUpdateIcm}
                        refreshHandler={refreshArtifact}
                        isLoading={this.props.isLoading}
                        validServiceChangeQueryUri={
                            this.props.dualCustodyFlow === DualCustodyFlowEnum.Full 
                            ? VALID_DUAL_CUSTODY_FULL_SERVICE_CHANGE_URI 
                            : VALID_DUAL_CUSTODY_LITE_SERVICE_CHANGE_URI
                        }
                        user={this.props.user}
                        dualCustodyFlow={this.props.dualCustodyFlow}
                        style={classNames.attestationBlock}
                    />

                    { this.props.dualCustodyFlow === DualCustodyFlowEnum.Full ? 
                        <QuestionaireSelection
                            key={this.props.artifact.fullyQualifiedId}
                            artifact={this.props.artifact}
                            root={this.props.questions}
                            style={classNames.attestationBlock}
                            onChange={this.handleQuestionaireSelection}
                        />
                        : <div />}

                    <ArtifactStateChange 
                        artifact={this.props.artifact}
                        user={this.props.user}
                        isLoading={this.props.isLoading}
                        dualCustodyFlow={this.props.dualCustodyFlow}
                        dualCustodyLiteValidation={this.props.dualCustodyLiteValidation}
                        style={classNames.attestationFooterBlock}
                        questionaireAnswers={this.state.questionaireAnswers}
                        questionaireIsComplete={(this.state.questionaireIsComplete || this.props.artifact.questionaireAnswers?.length > 0)}

                    /> 
                </Stack>
            );
        } 

        return (<div/>);
    }
};

const VALID_DUAL_CUSTODY_FULL_SERVICE_CHANGE_URI = `https://microsoft.visualstudio.com/OSGS/_queries/query-edit/6af134e1-5482-4d83-b2b0-9d60ae618b69/`;
const VALID_DUAL_CUSTODY_LITE_SERVICE_CHANGE_URI = `https://microsoft.visualstudio.com/OSGS/_queries/query-edit/3f833a0e-4fcd-440e-a837-3b0fcae4358a/`;

// URL LOGIC
const rootUrl = '#/';
const url_path = ['location', 'hash'];
const getUrl = R.pathOr('', url_path);
const isEmptyUrl = R.anyPass([
    R.isEmpty,
    R.equals(rootUrl)
]);
const isValidUrl = (url: string) => R.not(isEmptyUrl(url));
const stripUrlHashPrefix = R.replace(beginsWithUrlHash, '');
const replaceDotWithForwardSlash = R.replace(/\//g, '.');
const convertUrlToFqid = R.pipe(stripUrlHashPrefix, replaceDotWithForwardSlash);

const mapStateToProps = ({ artifactState, app }: ApplicationState) => ({
    artifact: artifactState.artifact,
    isLoading: app.loading,
    user: app.user,
    questions: app.questions,
    dualCustodyFlow: artifactState.dualCustodyFlow,
    dualCustodyLiteValidation: artifactState.dualCustodyLiteValidation,
    artifactEvents: artifactState.artifactEvents
})

const mapDispatchToProps: PropsFromDispatch = { 
    getArtifact: getArtifact,
    attest: attest,
    tryUpdateServiceChange: tryUpdateServiceChange,
    tryUpdateIcm: tryUpdateIcm,
    updateService: updateServiceAction,
    updateCustodian: updateCustodian,
    sendArtifactForReview: sendArtifactForReview,
    recallArtifact: recallArtifact,
    adminCloseArtifact: adminCloseArtifact,
    adminChangePhaseToAuthor: adminChangePhaseToAuthor,
    loadCodeContributorsIntoArtifact: loadCodeContributorsIntoArtifact,
    clearArtifact: clearArtifact,
    changeDualCustodyFlow: changeDualCustodyFlow,
    getEvents: getEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactAttestation);