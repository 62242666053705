import * as React from 'react';
import { 
    DefaultButton, 
    Dialog,
    DialogFooter, 
    PrimaryButton, 
    DialogType,
} from 'office-ui-fabric-react/lib/';
import { NeutralColors, SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

interface IConfirmationPopupProps {
    isOpen: boolean
    close: Function
    callback: Function
    title: string
    body: JSX.Element 
    buttonText: string
}

export const ConfirmationPopup: React.FC<IConfirmationPopupProps> = ({ isOpen, close, callback, title, body, buttonText }) =>
    <Dialog
        hidden={!isOpen}
        onDismiss={() => close()}
        dialogContentProps={{
            type: DialogType.largeHeader,
            title: title,
            titleProps: {style: { backgroundColor: SharedColors.cyanBlue10, color: NeutralColors.white, textAlign: 'center' } }
        }}        
        minWidth={350}
        modalProps={{ isBlocking: false, styles: { main: { maxWidth: 750 } }, }}>
            {body}
        <DialogFooter>
            <PrimaryButton 
                onClick={() => {
                    callback();
                    close();
                }} 
            text={buttonText} />
            <DefaultButton onClick={() => close()} text="Cancel" />
        </DialogFooter>
    </Dialog>

export default ConfirmationPopup;