import { propEq } from 'ramda';
import { encaseP } from 'fluture';
import { adalApiFetch } from './adal';

export const is200Response = propEq('status', 200);
export const is404Response = propEq('status', 404);

// REST
const POST = 'post';
export const Get = encaseP(url => adalApiFetch(fetch, url));
export const Post = encaseP(url => adalApiFetch(fetch, url, { method: POST }));
export const PostWithBody = (url: string, body: any) => encaseP(() => adalApiFetch(fetch, url, { method: POST, body }));