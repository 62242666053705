import * as React from 'react';
import { 
    DefaultButton, 
    Dialog, 
    DialogFooter, 
    PrimaryButton, 
    DialogType,
    Stack, Text, ITextStyles,
    Label, FontWeights, Checkbox
} from 'office-ui-fabric-react/lib/';
import { NeutralColors, SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

interface IConfirmationPopupProps {
    isOpen: boolean
    close: Function
    callback: Function
    title: string
    body: JSX.Element 
    buttonText: string
}

const popupWarningStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.bold
    }
};

const popupBodyStyles: ITextStyles = {
    root: {
        color: '#333333',
        fontWeight: FontWeights.regular
    }
};

export const AttestPopup: React.FC<IConfirmationPopupProps> = ({ isOpen, close, callback, title, body, buttonText }) => {

    const [check1, setcheck1] = React.useState(false)
    const [check2, setcheck2] = React.useState(false)
    const [check3, setcheck3] = React.useState(false)
    const [check4, setcheck4] = React.useState(false)
    
    const UpdateCheckboxState = (id: number, isChecked: boolean) => {
        if (id ==1 ) {
            setcheck1(isChecked)
        }
        else if (id == 2) {
            setcheck2(isChecked)
        }
        else if (id == 3) {
            setcheck3(isChecked)
        }
        else if (id == 4) {
            setcheck4(isChecked)
        }        
    }

    const CustodianAttestArtifactContent = (
        <Stack>
            <Text variant="mediumPlus" styles={popupWarningStyles}>Warning</Text>
            <Label>You are about to attest to this information. Please verify all statements below:</Label>
            <Text variant="medium" styles={popupBodyStyles}>
            <Checkbox label="Confirm that you observed the author (JIT user) close JIT and revoke their elevated access. All remote desktop/interactive login sessions have been closed. If using XTS, verify access has been revoked via Azure portal, and XTS has been closed." onChange={(_, isChecked?: boolean) =>
            isChecked ?
                UpdateCheckboxState(1, true) :
                UpdateCheckboxState(1, false)
        } />
            <Checkbox label="Confirm that you observed the entire JIT session and all changes made." onChange={(_, isChecked?: boolean) =>
            isChecked ?
                    UpdateCheckboxState(2, true) :
                    UpdateCheckboxState(2, false)
        } />
            <Checkbox label="Confirm that the changes made in production were only those that are documented in the associated ticket (ex. IcM/Service Change)." onChange={(_, isChecked?: boolean) =>
            isChecked ?
                    UpdateCheckboxState(3, true) :
                    UpdateCheckboxState(3, false)
        } />
                <Checkbox label="In cases where the details of the changes are not documented or approved prior in the incidents/ICMs, confirm that you approve the changes made and attest that they were appropriate." onChange={(_, isChecked?: boolean) =>
                isChecked ?
                    UpdateCheckboxState(4, true) :
                    UpdateCheckboxState(4, false)
        } />
            </Text>
        </Stack>
    );

   return <Dialog
       hidden={!isOpen}
        onDismiss={() => close()}
        dialogContentProps={{
            type: DialogType.largeHeader,
            title: title,
            titleProps: { style: { backgroundColor: SharedColors.cyanBlue10, color: NeutralColors.white, textAlign: 'center' } }
        }}
        minWidth={350}
        modalProps={{ isBlocking: false, styles: { main: { maxWidth: 750 } }, }}>
       {CustodianAttestArtifactContent}
        <DialogFooter>
            <PrimaryButton
                onClick={() => {
                    callback();
                    close();
               }}
               disabled={!(check1 && check2 && check3 && check4)}
                text={buttonText} />
            <DefaultButton onClick={() => close()} text="Cancel" />
        </DialogFooter>
    </Dialog>
}
export default AttestPopup;