import * as React from 'react';
import { Label } from 'office-ui-fabric-react/lib';
import {
    JitArtifact,

} from '../../store/artifact';
import { DropDown } from '../../components';
import { TitleText } from '../../shared';

import {
    canUpdateServiceByPhaseAndRole
} from '../../constants/actionLogic/serviceTree';


export interface IServiceSelectionProps {
    artifact: JitArtifact
    style: string
    updateService: Function
}

export default class extends React.Component<IServiceSelectionProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    render() {
        const ServiceContainerLabel = (
            <div>
                <Label> Service Name</Label>
                <Label>{this.props.artifact.serviceName}</Label>
            </div>
        );


        const ServiceContainerList = (
            <div>
                <Label> Select a Service</Label>
                <DropDown label="" value={this.props.artifact.serviceTree} errorMessage="" list={this.props.artifact.mappedServices} action={this.props.updateService} disable={!canUpdateServiceByPhaseAndRole(this.props)} />
            </div>
        );

        return (
            <div className={this.props.style}>
                <TitleText>Service Tree Information</TitleText>

                { this.props.artifact.mappedServices.length == 1 ? ServiceContainerLabel : ServiceContainerList}


            </div>
        );
    }
}