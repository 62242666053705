import { IAppStyles, IAppClassNameStyleProps } from './styles.types';
import { getGlobalClassNames } from 'office-ui-fabric-react';
import { getTheme } from '@uifabric/styling';
import { classNamesFunction, IProcessedStyleSet, } from 'office-ui-fabric-react';
import { mergeStyles } from '@uifabric/merge-styles';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

export const MainPanelInnerContent = mergeStyles({
  marginRight: 'auto',
  marginLeft: 'auto',
  padding: '25px',
  backgroundColor: NeutralColors.gray20 
});

const GlobalClassNames: { [key in keyof IAppStyles]: string } = {
    attestation: 'msat-attestation',
    attestationBlock: 'msat-attestation-block',
    attestationClosed: 'msat-attestation-closed',
    attestationFooterBlock: 'msat-attestation-footer-block',
    content: 'msat-content',
    header: 'msat-header',
    linkTitle: 'msat-linkTitle',
    labelTitle:'msat-labelTitle',
    loadingIndicator: 'msat-loading-indicator',
    main: 'msat-main',
    microsoftLogo: 'msat-msft-logo',
    page: 'msat-page', 
    root: 'msat-root',
    sidebar: 'msat-sidebar', 
};

const getStyles = (props: IAppClassNameStyleProps): IAppStyles => {
    const { theme } = props;
    const classNames = getGlobalClassNames(GlobalClassNames, theme);
    return {
        attestation: [
            {
                marginTop: '20px', 
                marginBottom: '20px', 
                marginRight: 'auto', 
                marginLeft: 'auto', 
                backgroundColor: NeutralColors.white, 
                borderColor: NeutralColors.gray30,
                borderWidth: '1px',
                borderStyle: 'solid',
                width: '97%', 
                overflow: 'auto', 
                minHeight: '50%',
            }
        ],
        attestationBlock: [
            classNames.attestationBlock,
            {
                marginRight: 'auto',
                marginLeft: 'auto',
                padding: '25px',
                backgroundColor: NeutralColors.white,
                borderBottomStyle: 'solid', 
                borderBottomColor: 'black', 
                borderBottomWidth: '1px', 
                borderColor: NeutralColors.gray50, 
                width: '90%',
            }
        ],
        attestationClosed: [
            classNames.attestationClosed,
            {
                backgroundColor: NeutralColors.gray100 + ' !important'
            }
        ],
        attestationFooterBlock: [
            classNames.attestationFooterBlock,
            {
                marginRight: 'auto',
                marginLeft: 'auto',
                padding: '25px',
                backgroundColor: NeutralColors.white,
                width: '90%',
            }
        ],
        content: [
            classNames.content,
            {
                overflow: 'auto', 
                minHeight: '90vh',
                backgroundColor: NeutralColors.white
            }
        ],
        header: [
            classNames.header,
            {
                overflow: 'hidden',
                backgroundColor: theme.semanticColors.bodyBackground,
                minHeight: 47,
                padding: '0 32px',
                boxShadow: theme.effects.elevation16
            }
        ],
        linkTitle: [
            classNames.linkTitle,
            {
              textDecoration: 'none',
              color: theme.semanticColors.bodyText,
              fontWeight: '600',
              fontSize: theme.fonts.medium.fontSize
            }
        ],
        labelTitle: [
            classNames.labelTitle,
            {
                textDecoration: 'none',
                color: theme.semanticColors.listText,
                fontWeight: '600',
                fontSize: theme.fonts.small.fontSize
            }
        ],
        loadingIndicator: [
            classNames.loadingIndicator,
            {
                marginTop: -10,
            }
        ],
        main: [
            classNames.main,
            {
                width: '75vh',
                height: '95vh',
                overflow: 'auto',
                marginLeft: '0 !important',
                backgroundColor: NeutralColors.gray10
            }
        ],
        microsoftLogo: [
          classNames.microsoftLogo,
          { 
            width: '120px', 
            display: 'block'
          }
        ],
        page: [
            classNames.page,
            {
              minHeight: '100vh',
              selectors: {
                  ':global(body)': {
                      padding: 0,
                      margin: 0
                  }
              },
            }
        ],
        root: [
            classNames.root,
        ],
        sidebar: [
            classNames.sidebar,
            { 
                backgroundColor: NeutralColors.white, 
                height: '95vh', 
                overflow: 'auto', 
                width: '20vh', 
                marginLeft: 'auto', 
                marginRight: 'auto'
            }
        ],
    }
}

const theme = getTheme();
const styleProps: IAppClassNameStyleProps = { theme, className: '' }
const reactStyles: IAppStyles = getStyles(styleProps);
const getClassNameCallback = classNamesFunction<IAppClassNameStyleProps, IAppStyles>();
export const classNames: IProcessedStyleSet<IAppStyles> = getClassNameCallback(reactStyles, styleProps);