import * as React from 'react';
import * as R from 'ramda';
import { 
    Stack, 
    PrimaryButton, 
    Persona,
    PersonaSize,
    Label,
} from 'office-ui-fabric-react/lib';
import { Input } from '../../components';
import { 
    canUpdateCustodianByPhaseAndRole, 
    canSaveCustodian, 
    showUpdateCustodianTextfield,
    isCustodianRequired,
} from '../../constants/actionLogic/custodian';
import { classNames, TitleText } from '../../shared';
import { JitArtifact, DualCustodyFlowEnum } from '../../store/artifact';
import { User } from '../../store/app';
import { isValidEmail, isMicrosoftEmail } from '../../constants/regex';
import { hasCustodian } from '../../constants/actionLogic';

interface IRolesProps {
    artifact: JitArtifact
    style: string
    updateCustodian: Function
    isLoading: boolean
    user: User
    dualCustodyFlow: DualCustodyFlowEnum
}

interface IRolesState {
    custodianInput: string
    errorMessage: string
}

export default class extends React.Component<IRolesProps, IRolesState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            custodianInput: '',
            errorMessage: '',
        }

        this._onChangeCustodian = this._onChangeCustodian.bind(this);
        this._updateCustodian = this._updateCustodian.bind(this);
    }
    
    _onChangeCustodian(custodianInput: string) {
        if (custodianInput === ''){
            this.setState({ errorMessage: '' });
        }
        this.setState({ custodianInput });
    }

    _updateCustodian() {
        if (isValidEmail(this.state.custodianInput)){
            if (isMicrosoftEmail(this.state.custodianInput)){
                this.setState({ errorMessage: '' })
                this.props.updateCustodian(this.state.custodianInput);
            } else {
                this.setState({ errorMessage: 'Not a Microsoft email' });
            }
            
        } else {
            this.setState({ errorMessage: 'Not a valid email' })
        }
    }

    render() {
        const UpdateCustodian = (
            <Stack horizontal>
                <Stack.Item grow align="stretch">
                    <Input 
                        value={this.state.custodianInput}
                        onChange={this._onChangeCustodian}
                        label="Custodian Alias (enter alias@microsoft.com, NOT firstname.lastname@microsoft.com)"
                        required={isCustodianRequired(this.props)}
                        disabled={!canUpdateCustodianByPhaseAndRole(this.props)}
                        errorMessage={this.state.errorMessage}
                    />
                </Stack.Item>

                <Stack.Item align="baseline">
                    <PrimaryButton 
                        styles={{root: {marginTop: 29}}}
                        text="Save"
                        disabled={!canSaveCustodian(R.assoc('custodianInput', this.state.custodianInput, this.props))}
                        onClick={this._updateCustodian}
                    />
                </Stack.Item>
            </Stack>
        );

        return (
            <div className={this.props.style}>
                <TitleText>Roles</TitleText>
                <div style={{marginBottom: 20}}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <div style={{ paddingLeft: '32px' }} >{this.props.artifact.authorName != "" ? <Label className={classNames.labelTitle}>{this.props.artifact.authorName}</Label> : null}</div>
                        <Persona
                            size={PersonaSize.size24}
                            text={this.props.artifact.author}                            
                            secondaryText="Author"
                            showSecondaryText={true}
                            imageInitials="A"                            
                        />

                        <div style={{ paddingLeft: '32px' }} >{this.props.artifact.custodianName != "" ? <Label className={classNames.labelTitle}>{this.props.artifact.custodianName}</Label> : null}</div>
                        {hasCustodian(this.props) ?
                            <Persona
                                size={PersonaSize.size24}
                                text={this.props.artifact.custodian}                                
                                secondaryText="Custodian"
                                showSecondaryText={true}
                                imageInitials="C"                                
                            />                   
                        : null }

                    </Stack>
                </div>

                {/* UPDATE CUSTODIAN */}
                { showUpdateCustodianTextfield(this.props) ? UpdateCustodian : null }

            </div>
        )
    }
}