import { AuthenticationContext, adalFetch as adalFetchWrapper, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    clientId: 'ddd299e4-b5c1-472a-801b-f58a424f7c4d',
    endpoints: {
        api: 'ddd299e4-b5c1-472a-801b-f58a424f7c4d'
    },
    cacheLocation: 'localStorage'
};
export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) => adalFetchWrapper(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);