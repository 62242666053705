import * as React from 'react';
import * as R from 'ramda';
import { 
    Stack, 
    DefaultButton, 
    TooltipHost, 
    TooltipDelay, 
    DirectionalHint, 
    ITooltipProps,
    ITooltipHostStyles,
    IconButton,
} from 'office-ui-fabric-react/lib/';
import { mapIndexed } from '../constants';
import { ConfirmationPopup } from './';
import AttestPopup from './AttestPopup';

interface IActionButton {
    style: string
    buttonText: string
    buttonIcon: string
    confirmationTitle: string
    confirmationBody: JSX.Element
    confirmationButtonText: string
    tooltipContent: string[] 
    callback: Function
    isDisabled: boolean
}

interface IActionState {
    popupOpen: boolean
}

export default class extends React.Component<IActionButton, IActionState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            popupOpen: false,
        }

        this._openPopup = this._openPopup.bind(this);
        this._closePopup = this._closePopup.bind(this);
    }

    _openPopup() {
        this.setState({ popupOpen: true });
    }

    _closePopup() {
        this.setState({ popupOpen: false })
    }

    public render() {
        const tooltipId = 'tooltip';
        const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', marginLeft: 'auto', marginRight: 'auto' } };
        const tooltipProps: ITooltipProps = {
            onRenderContent: () => R.ifElse(R.isEmpty, R.always(null), getTooltips)(this.props.tooltipContent)
        };

    return (
        <div className={this.props.style}>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                <TooltipHost
                    tooltipProps={tooltipProps}
                    delay={TooltipDelay.zero}
                    id={tooltipId}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={hostStyles}
                >
                    <DefaultButton 
                        primary
                        text={this.props.buttonText}
                        onClick={this._openPopup}
                        iconProps={{iconName: this.props.buttonIcon}}
                        disabled={this.props.isDisabled}
                    />
                </TooltipHost>

            </Stack>
            {/*For Attest use different Popup with checkboxes */}
            {this.props.confirmationButtonText != "Attest" ?
                <ConfirmationPopup
                    isOpen={this.state.popupOpen}
                    close={this._closePopup}
                    callback={this.props.callback}
                    title={this.props.confirmationTitle}
                    body={this.props.confirmationBody}
                    buttonText={this.props.confirmationButtonText}
                /> :
                <AttestPopup
                    isOpen={this.state.popupOpen}
                    close={this._closePopup}
                    callback={this.props.callback}
                    title={this.props.confirmationTitle}
                    body={this.props.confirmationBody}
                    buttonText={this.props.confirmationButtonText}
                />
                }
        </div>
        );
   }
}

// button tooltips
const tooltipItem = (children: any, index: number) => 
    <li key={index}>
        <IconButton iconProps={{ iconName: 'Cancel'}} style={{ color: 'red', verticalAlign: 'middle'}}/>
        {children}
    </li>
const tooltipContainer = (children: any) => <ul style={{ margin: 10, padding: 0}}>{children}</ul>
const getTooltips = R.pipe(mapIndexed(tooltipItem), tooltipContainer)